import React, { useEffect, useState } from 'react';
import { DeviceCommandsTableContent } from './DeviceCommandsTable.view';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery } from '@tanstack/react-query';
import { DeviceCommandsTableProps } from './DeviceCommandsTable.type';
import { ActionButton, ModalButton } from './DeviceCommandsTable.style';
import { ModalActionTypesEnum, ModalAtom, ModalDispatcher } from '../../../states/global/Modal';
import { useRecoilState, useSetRecoilState } from 'recoil';
import { RefreshTable } from '../../Ui/Components/UiTable2/UiTable.atom';
import { ActiveDeviceCommandsTab } from './DeviceCommandsTable.state';
import ClearIcon from '@mui/icons-material/Clear';
import { optionsCodeBook } from '../../Ui/Components/UiDropDown/UiDropDown.type';
import { DeviceCommandsTableModel } from '../../../models/Device.type';
import { Success } from '../../Popup/Popup';
import DeviceCommand from '../../../api/DeviceCommand';
import UiDropDown from '../../Ui/Components/UiDropDown/UiDropDown';
import { TableAtom } from '../../../states/component/Table';
import { faInfoCircle } from '@fortawesome/pro-solid-svg-icons';
import { CommandContent } from './Components/CommandContent/CommandContent';
import { Tooltip } from '@mui/material';
import useConverter from 'components/CustomHooks/Converter/Converter';
import { Column } from 'models/Table.type';
import { Wrapper } from 'helpers/wrapper';
import UiIcon from 'components/Ui/Components/UiIcon/UiIcon';

const deviceCommandApi = new DeviceCommand();

const DeviceCommandsTable: React.FC<DeviceCommandsTableProps> = (props): JSX.Element => {
    const [historyCommandsCodebook, setHistoryCommandsCodebook] = useState<optionsCodeBook[]>();
    const { fromUTCToTimezone } = useConverter();
    const setActiveTab = useSetRecoilState(ActiveDeviceCommandsTab);
    const [tableState, setTableState] = useRecoilState(TableAtom('deviceCommandsTableTerminal-Table'));
    const [modalAtom, setStateModalAtom] = useRecoilState(ModalAtom);
    const setRefreshTableAtom = useSetRecoilState(RefreshTable('deviceCommandsTableTerminal-RefreshTableAtom'));
    const modalDispach = ModalDispatcher(modalAtom, setStateModalAtom);
    const { t: translate } = useTranslation();
    const handleTabChange = (event, newTabIndex: number): void => {
        setActiveTab({ details: newTabIndex });
    };

    const parseData = (data): optionsCodeBook[] => {
        return data.deviceCommand.reduce((acc: optionsCodeBook[], curr) => {
            acc.push({ id: curr.name, name: `${translate(`device.cmd.${curr.name}`)}` });
            return acc;
        }, []);
    };

    useQuery([`DeviceCommandsQuery-${props.deviceId}`], () => deviceCommandApi.getRecentSplitHistoryDeviceCommand(), {
        retry: false,
        keepPreviousData: true,
        refetchOnWindowFocus: false,
        onSuccess: (dataOnSuccess) => setHistoryCommandsCodebook(parseData(dataOnSuccess))
    });

    const { mutate: cancelCommandMutation, isLoading: cancelCommandLoading } = useMutation(
        ['cancelCommandMutation'],
        deviceCommandApi.deletePendingRequest,
        {
            onSuccess: () => {
                Success({
                    text: translate('t.canceled_command')
                });
            },
            onSettled: () => {
                updateModal(false);
                setRefreshTableAtom(true);
            }
        }
    );

    const updateModal = (isOpen?: boolean) => {
        modalDispach({
            type: ModalActionTypesEnum.SET_MODAL_STATE,
            ModalPropsPayload: {
                ...modalAtom,
                isOpen: typeof isOpen === 'undefined' ? modalAtom.isOpen : isOpen
            }
        });
    };

    const showContent = (content, result) => {
        const contentModal = {
            id: 'showContent',
            leftTitle: translate('t.command_content'),
            content: <CommandContent content={content} result={result} />,
            buttons: <></>,
            width: 600,
            height: 350,
            widthUnit: 'px',
            onClose: () =>
                modalDispach({
                    type: ModalActionTypesEnum.SET_MODAL_STATE,
                    ModalPropsPayload: {
                        ...contentModal,
                        isOpen: false
                    }
                })
        };

        modalDispach({
            type: ModalActionTypesEnum.SET_MODAL_STATE,
            ModalPropsPayload: {
                ...contentModal,
                isOpen: true
            }
        });
    };

    const handleModalCancel = (id: number) => {
        const contentModalCancel = {
            id: 'CancelCommand',
            cancelButtonText: translate('t.no'),
            leftTitle: translate('t.cancel_command'),
            content: <>{translate('p.are_you_sure_cancel_command')}</>,
            buttons: (
                <ModalButton
                    skin='success'
                    loading={cancelCommandLoading}
                    onClick={() => {
                        cancelCommandMutation({ deviceId: props.deviceId, id: id });
                    }}
                >
                    {translate('t.yes')}
                </ModalButton>
            ),
            width: 500,
            height: 100,
            widthUnit: 'px',
            onClose: () =>
                modalDispach({
                    type: ModalActionTypesEnum.SET_MODAL_STATE,
                    ModalPropsPayload: {
                        ...contentModalCancel,
                        isOpen: false
                    }
                })
        };

        modalDispach({
            type: ModalActionTypesEnum.SET_MODAL_STATE,
            ModalPropsPayload: {
                ...contentModalCancel,
                isOpen: true
            }
        });
    };

    const columns: Column<DeviceCommandsTableModel>[] = [
        {
            Header: 'ID',
            accessor: (row: DeviceCommandsTableModel) => row.id,
            disableFilters: true,
            disableSortBy: true,
            Cell: (props: { row: { original: DeviceCommandsTableModel } }) => <>{props.row.original.id ?? '-'}</>
        },

        {
            Header: translate('t.code'),
            accessor: (row: DeviceCommandsTableModel) => row.command,
            disableFilters: true,
            disableSortBy: true,
            width: 60,
            Cell: (props: { row: { original: DeviceCommandsTableModel } }) => <>{props.row.original.command ?? '-'}</>
        },
        {
            Header: translate('t.command_name'),
            accessor: (row: DeviceCommandsTableModel) => row.name,
            disableFilters: true,
            disableSortBy: true,
            width: 180,
            Cell: (props: { row: { original: DeviceCommandsTableModel } }) =>
                props.row.original.name ? (
                    <>
                        {translate(`device.cmd.${props.row.original.name}`)}{' '}
                        <Tooltip
                            disableFocusListener
                            disableTouchListener
                            title={
                                props.row.original.content
                                    ? `Content: ${props.row.original.content} `
                                    : translate('t.none')
                            }
                        >
                            <span>
                                <UiIcon icon={faInfoCircle} />
                            </span>
                        </Tooltip>
                    </>
                ) : (
                    <>-</>
                ),
            Filter: ({ setFilter, state }) => {
                return (
                    <>
                        <UiDropDown
                            value={
                                state.filters.filter((filter) => filter.id === 'commandNameFilter')[0]?.value.value ||
                                ' '
                            }
                            inputName='commandNameDropdown'
                            options={historyCommandsCodebook ?? []}
                            label={translate('t.command_name')}
                            getValue={() => {
                                /* const value = !newValue
                            ? undefined
                            : {
                                  name: `${translate('t.battery')} ${translate('t.from')}`,

                                  value: newValue.target.value
                              }; */
                                setFilter('commandNameFilter', 'dd'); // value); */
                            }}
                            defaultValue={' '}
                        />
                    </>
                );
            }
        },

        {
            Header: translate('t.result'),
            accessor: (row: DeviceCommandsTableModel) => row.result,
            disableFilters: true,
            disableSortBy: true,
            width: 150,
            Cell: (props: { row: { original: DeviceCommandsTableModel } }) =>
                (
                    <>
                        <Tooltip
                            disableFocusListener
                            disableTouchListener
                            title={translate('t.show_content')}
                            disableInteractive
                        >
                            <ActionButton
                                className='contentButton'
                                onClick={() => showContent(props.row.original.content, props.row.original.result)}
                            >
                                <span>{props.row.original.result ?? '{}'}</span>
                            </ActionButton>
                        </Tooltip>
                    </>
                ) ?? <>-</>
        },
        {
            Header: translate('t.status'),
            accessor: (row: DeviceCommandsTableModel) => row.executed,
            disableFilters: true,
            disableSortBy: true,
            width: 70,
            Cell: (props: { row: { original: DeviceCommandsTableModel } }) => (
                <>{(props.row.original.executed == true ? translate('t.executed') : translate('t.pending')) ?? '-'}</>
            )
        },

        {
            Header: translate('t.date_of_creation'),
            accessor: (row: DeviceCommandsTableModel) => row.createdAt,
            disableFilters: true,
            disableSortBy: true,
            Cell: (props: { row: { original: DeviceCommandsTableModel } }) => (
                <>{fromUTCToTimezone(props.row.original.createdAt || '', true) ?? '-'}</>
            )
        },

        {
            Header: translate('t.date_of_execution'),
            accessor: (row: DeviceCommandsTableModel) => row.executedAt,
            disableFilters: true,
            disableSortBy: true,
            Cell: (props: { row: { original: DeviceCommandsTableModel } }) => (
                <>{fromUTCToTimezone(props.row.original.executedAt || '', true) ?? '-'}</>
            )
        },

        {
            Header: translate('t.cancel'),
            disableFilters: true,
            disableSortBy: true,
            width: 70,
            Cell: (props: { row: { original: DeviceCommandsTableModel } }) => (
                <div>
                    {!props.row.original.executed && (
                        <ActionButton
                            onClick={() => {
                                handleModalCancel(props.row.original.id);
                            }}
                        >
                            <ClearIcon />
                        </ActionButton>
                    )}
                </div>
            )
        }
    ];

    useEffect(() => {
        updateModal();
    }, [cancelCommandLoading]);

    useEffect(() => {
        setTableState({ ...tableState, queryPageSize: 100 });
    }, []);

    return (
        <DeviceCommandsTableContent
            {...props}
            data-testid={'DeviceCommandsTable-testid'}
            handleTabChange={handleTabChange}
            columns={columns}
        />
    );
};

export default Wrapper(DeviceCommandsTable);

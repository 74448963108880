import React, { useEffect } from 'react';
import './App.css';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import { useRecoilState, useRecoilValue } from 'recoil';
import PopupContainer from '../../components/Popup/PopupContainer';
import { MenuRoutes } from '../MainMenu/MenuOptions';
import { WrapperApp } from 'helpers/wrapper';
import ErrorPage from '../../pages/Error404';
import Login from '../../pages/Login/Login';
import createTheme from '../Ui/Theme';
import createThemeDark from '../Ui/ThemeDark';
import { StyledEngineProvider, ThemeProvider, Theme as ThemeType } from '@mui/material/styles';
import Layout from '../../components/Layout';
import { RequiresAuth, RequiresPermissions } from './PrivateRoutes';
import { loggedIn } from 'helpers/authentication';
import { Theme, ThemeMode, ThemeType as ThemeModeType } from '../../states/global/Theme';
import CssBaseline from '@mui/material/CssBaseline';
import { ProSidebarProvider } from 'react-pro-sidebar';
import SeverityRank from '../../pages/SeverityRank/SeverityRank';
import PrivacyPolicy from 'pages/PrivacyPolicy/PrivacyPolicy';
import { LicenseInfo } from '@mui/x-license-pro';
import { MUI_X_LICENSE_KEY } from 'variables/index';
import ChangesPassword from 'pages/ChangesPassword/ChangesPassword';
import ReportIssue from 'pages/ReportIssue/ReportIssue';
import { CheckForbiddenRedirection, UserInfo } from 'states/global/User';
import CustomerRedirection from 'pages/CustomerRedirection/CustomerRedirection';
import Redirect from 'pages/Redirect/Redirect';
import CustomerSettings from 'components/Queries/CustomerSettings';
import { rcUrl } from 'helpers';

LicenseInfo.setLicenseKey(MUI_X_LICENSE_KEY);

const testingEnviroments = ['localhost', 'test-', 'dev'];

export const getTheme = (theme: ThemeModeType): ThemeType => {
    switch (theme.mode) {
        case ThemeMode.dark:
            return createThemeDark(theme.easyReadingMode, theme.bigFonts);
        default:
            return createTheme(theme.easyReadingMode, theme.bigFonts);
    }
};

const App: React.FC = () => {
    const MenuOptions = MenuRoutes();
    const theme = useRecoilValue(Theme);
    const domain = location.hostname;
    const pathname = location.pathname;
    const userInfo = useRecoilValue(UserInfo);
    const [forbidRedirection, setForbidRedirection] = useRecoilState(CheckForbiddenRedirection);
    const hostname = userInfo.user?.customer.hostname || '';

    if (!forbidRedirection) {
        setForbidRedirection(location.search.includes('no-redir'));
    }

    useEffect(() => {
        const noRedirectNeeded =
            forbidRedirection ||
            forbidRedirection === undefined ||
            (domain.search(new RegExp(testingEnviroments.join('|'))) > -1 &&
                (hostname === 'webtrack.atmstechnology.com' || !hostname)) ||
            pathname.includes('customer-redirection');
        const isRC = location.hostname.includes('rc');
        const domainPreffix = hostname.split('.')[0];
        const customerDomain = isRC ? rcUrl[domainPreffix] : hostname;
        if (!noRedirectNeeded && customerDomain !== domain && hostname) {
            location.href = '/customer-redirection';
        }
    }, [pathname, domain, hostname, forbidRedirection]);

    useEffect(() => {
        localStorage.setItem('theme', JSON.stringify(theme));
    }, [theme]);

    return (
        <ThemeProvider theme={getTheme(theme)}>
            <div className='Ui-scroll'>
                <CssBaseline enableColorScheme />
                <StyledEngineProvider injectFirst>
                    <BrowserRouter basename={process.env.PUBLIC_URL}>
                        <CustomerSettings>
                            <Routes>
                                <Route
                                    element={
                                        <RequiresAuth>
                                            <ProSidebarProvider>
                                                <Layout />
                                            </ProSidebarProvider>
                                        </RequiresAuth>
                                    }
                                >
                                    {MenuOptions.map((item) => {
                                        return (
                                            <Route
                                                key={item.key}
                                                path={item.path}
                                                element={
                                                    <RequiresPermissions
                                                        requiredRoles={item.requiredRoles}
                                                        bes={item.bes}
                                                        besDrivingAssistance={item.besDrivingAssistance}
                                                        tableActivation={item.tableActivation}
                                                        temperaturePrediction={item.temperaturePrediction}
                                                    >
                                                        <>{item.element}</>
                                                    </RequiresPermissions>
                                                }
                                            />
                                        );
                                    })}
                                    <Route path='/severity-rank/:id' key='severity-rank' element={<SeverityRank />} />
                                </Route>

                                <Route
                                    path='/'
                                    element={
                                        loggedIn() ? (
                                            <Navigate to='/dashboard' replace={true} />
                                        ) : (
                                            <Navigate to='/login' replace={true} />
                                        )
                                    }
                                ></Route>
                                <Route path='/login' key='login' element={<Login />} />
                                <Route path='/no-permissions' element={<ErrorPage code={401} />} />
                                <Route key='error-page404' element={<ErrorPage code={404} />} />
                                <Route path='/privacy-policy' element={<PrivacyPolicy />} />
                                <Route path='/request-support' element={<ReportIssue />} />
                                <Route path='/reset-password/:hash' element={<ChangesPassword />} />
                                <Route path='/change-password' element={<ChangesPassword />} />
                                <Route path='*' key='error-page404' element={<ErrorPage code={404} />} />
                                <Route
                                    path={'/customer-redirection'}
                                    key='customer-redirection'
                                    element={<CustomerRedirection />}
                                />
                                <Route path='/redir/:hash' element={<Redirect />} />
                            </Routes>
                        </CustomerSettings>
                    </BrowserRouter>
                </StyledEngineProvider>
            </div>
            <PopupContainer />
        </ThemeProvider>
    );
};

export default WrapperApp(App);

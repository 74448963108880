import React, { useEffect, useState } from 'react';
import { TemperatureExposureTopbarProps } from './TemperatureExposureTopbar.type';
import { UserInfo } from '../../../states/global/User';
import { useRecoilValue, useRecoilState, useResetRecoilState, useSetRecoilState } from 'recoil';
import { DateTime, Settings } from 'luxon';
import { SUCCESS } from '../../Ui/colors';
import { Button, TemperatureExposureTopbarContainer } from './TemperatureExposureTopbar.style';
import { useTranslation } from 'react-i18next';
import { TemperatureExposureDate } from './TemperatureExposureTopbar.atom';
import LazyloadingAutocomplete from '../LazyloadingAutocomplete/LazyloadingAutocomplete';
import UiDateRangePicker2 from 'components/Ui/Components/UiDateRangePicker2';
import VehicleApi from '../../../api/Vehicle';
import { SelectedVehicle } from '../../../states/global/Statistics';
import { VARIANT_BUTTON } from '../../Ui/variables';
import Grid from '@mui/material/Grid';
import { PeriodTypeEnum } from 'components/Ui/Components/UiDateRangePicker2/UiDateRangePicker2.type';
import { Wrapper } from 'helpers/wrapper';

const Vehicle = new VehicleApi();

const TemperatureExposureTopbar: React.FC<TemperatureExposureTopbarProps> = (props): JSX.Element => {
    const userInfo = useRecoilValue(UserInfo);
    const { t: translate } = useTranslation();
    const setSelectedVehicle = useSetRecoilState(SelectedVehicle);
    const [dateRange, setDateRange] = useRecoilState(TemperatureExposureDate);
    const [vehicle, setVehicle] = useState<{
        id: number;
        name: string;
        icon: string;
        distribution?: string;
    } | null>(null);
    const resetDateRange = useResetRecoilState(TemperatureExposureDate);

    useEffect(() => {
        setDateRange({
            dateFrom: DateTime.local().minus({ months: 3 }),
            dateTo: DateTime.local(),
            appliedDateFrom: DateTime.local().minus({ months: 3 }),
            appliedDateTo: DateTime.local(),
            isValidRange: true
        });
    }, [userInfo.user?.userSetting.timezone.timezoneName]);

    useEffect(() => {
        resetDateRange();
        () => {
            resetDateRange();
        };
    }, []);

    return (
        <TemperatureExposureTopbarContainer data-testid='TemperatureExposureTopbar-testid'>
            <Grid container spacing={1} marginTop={0} justifyContent='center'>
                {userInfo.user?.userSetting.timezone.timezoneName === Settings.defaultZone.zoneName && (
                    <Grid item>
                        <UiDateRangePicker2
                            adjustToPeriod={{ period: 3, periodType: PeriodTypeEnum.MONTHS }}
                            dateFrom={DateTime.fromISO(dateRange.dateFrom)}
                            dateTo={DateTime.fromISO(dateRange.dateTo)}
                            onDateChange={(dateRange) => {
                                if (dateRange)
                                    setDateRange((current) => ({
                                        ...current,
                                        dateFrom: DateTime.fromISO(dateRange[0]),
                                        dateTo: DateTime.fromISO(dateRange[1])
                                    }));
                            }}
                            testIdFrom='UiDatePickerTemperatureExposureTyreDateFrom'
                            testIdTo='UiDatePickerTemperatureExposureTyreDateTo'
                            onlyDate={true}
                        />
                    </Grid>
                )}

                <Grid item>
                    {props.showVehicleLazyloading && (
                        <LazyloadingAutocomplete
                            id={'temperature-exposure-top-bar'}
                            $testid='TemperatureExposureTopBar'
                            size='small'
                            label={translate('t.vehicle')}
                            query={Vehicle.getTable}
                            getOptionLabel={(option) => ({
                                text: option.name,
                                html: undefined
                            })}
                            clearButton={true}
                            queryId='vehicle-list'
                            optionKey='name'
                            optionValue='id'
                            onValueChange={(newVehicle, label, item) => {
                                if (item?.id && item?.name && item?.icon) {
                                    setVehicle({
                                        id: item.id,
                                        name: item.name,
                                        icon: item.icon
                                    });
                                }
                            }}
                            querySortBy={{
                                desc: false,
                                id: 'vehicleName'
                            }}
                            width={250}
                        />
                    )}
                </Grid>
                <Grid item>
                    <Button
                        loading={props.isLoading}
                        disabled={
                            props.isLoading || !dateRange.isValidRange || (props.showVehicleLazyloading && !vehicle?.id)
                        }
                        skin={SUCCESS}
                        color='primary'
                        variant={VARIANT_BUTTON}
                        onClick={() => {
                            setDateRange((current) => ({
                                ...current,
                                appliedDateFrom: current.dateFrom,
                                appliedDateTo: current.dateTo
                            }));
                            if (vehicle) {
                                setSelectedVehicle(vehicle);
                            }
                        }}
                        testid='ExposureTemperatureApplyBtn'
                    >
                        {translate('t.apply')}
                    </Button>
                </Grid>
            </Grid>
        </TemperatureExposureTopbarContainer>
    );
};

export default Wrapper(TemperatureExposureTopbar);

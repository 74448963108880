import PortalApiClient from 'helpers/api/PortalApiClient';
import {
    Sensor,
    GetSystemTotalModel,
    SystemTotalDetailGet,
    SystemTotalDetailParams,
    SensorsWithPaginator,
    SensorTableResponse,
    replaceSensorOnWheelProps,
    UnmountSensorProps,
    MountSensorProps,
    ReplaceSensorProps,
    InspectionParams,
    InspectionResponse,
    getAttachedSensorVehicleResponse
} from '../models/Sensors.type';
import { PaginatorParams } from '../models/Paginator.type';
import { deepCopyObj, getParams } from 'helpers';
import { TableInitStateProps } from '../components/Ui/Components/UiTable2/UiTable.type';
import { SwitchPrioritiesReturnType } from '../models/Sensors.type';

export default class Sensors extends PortalApiClient<Sensor> {
    route = 'sensor';

    getSystemTotalsNew = async (): Promise<GetSystemTotalModel> => {
        return await this.get({ extendUrl: 'system-total' });
    };

    getSystemTotalsDetail = async (
        type: string,
        status: string,
        transmitting: boolean
    ): Promise<SystemTotalDetailGet> => {
        let params: SystemTotalDetailParams = { type, status, transmitting: transmitting ? 1 : 0 };

        return this.getByCriteria({
            extendUrl: 'system-total/detail',
            criteria: params
        });
    };

    getNotTransmitting = async (page: number, limit: number): Promise<SensorsWithPaginator> => {
        let params: PaginatorParams = { page, limit };
        return this.getByCriteria({
            extendUrl: 'not-transmitting',
            criteria: params
        });
    };

    disablePressureOrTemperatureStatus = async (id: string): Promise<Object> => {
        return await this.post({ extendUrl: `test/${id}` });
    };

    getSensors = async (props: TableInitStateProps): Promise<SensorTableResponse> => {
        let filter = props.queryPageFilter.find((element) => element.id == 'inactive')
            ? [...props.queryPageFilter]
            : [...props.queryPageFilter, { id: 'active', value: { name: 'active', value: '1' } }];

        let endpointParams = deepCopyObj(props);

        if (endpointParams.queryPageSortBy.length > 0 && endpointParams.queryPageSortBy[0].id === 'battery_low') {
            endpointParams.queryPageSortBy[0].id = 'sensorStatsBattery';
        }

        return await this.getTable({
            criteria: getParams({ ...endpointParams, queryPageFilter: filter })
        });
    };

    getRemovedSensors = async (props: TableInitStateProps): Promise<SensorTableResponse> => {
        let filter = [...props.queryPageFilter, { id: 'removed', value: { name: 'removed', value: '1' } }];
        return await this.getTable({
            criteria: getParams({ ...props, queryPageFilter: filter })
        });
    };

    getSensorsExport = async (props: TableInitStateProps): Promise<string> => {
        let filter = props.queryPageFilter.find((element) => element.id == 'inactive')
            ? [...props.queryPageFilter]
            : [...props.queryPageFilter, { id: 'active', value: { name: 'active', value: '1' } }];
        return this.getByCriteria({
            extendUrl: 'export',
            criteria: getParams({
                ...props,
                queryPageFilter: filter,
                queryPageSortBy:
                    props.queryPageSortBy.filter((element) => element.id === 'battery_low').length > 0
                        ? [{ id: 'sensorStatsBattery', desc: props.queryPageSortBy[0].desc }]
                        : [...props.queryPageSortBy]
            })
        });
    };

    getRemovedSensorsExport = async (props: TableInitStateProps): Promise<string> => {
        let filter = [...props.queryPageFilter, { id: 'removed', value: { name: 'removed', value: '1' } }];
        return this.getByCriteria({
            extendUrl: 'export',
            criteria: getParams({ ...props, queryPageFilter: filter })
        });
    };

    patchSensorsComment = async (props): Promise<Sensor> => {
        return await this.patch({
            extendUrl: `${props.commentId}/comment`,
            data: props.comment
        });
    };

    patchSensorsAnalysis = async (props): Promise<Sensor> => {
        return await this.patch({
            extendUrl: `${props.analysisId}/analysis`,
            data: { analysis: props.analysis.comment }
        });
    };

    patchSensorType = async (props): Promise<Sensor> => {
        return await this.patch({
            extendUrl: `${props.id}/sensor-type`,
            data: props.data
        });
    };

    patchSensorStatus = async (props): Promise<Sensor> => {
        return await this.patch({
            extendUrl: `${props.id}/status/${props.name}`,
            data: props.data
        });
    };

    patchTemperatureStatus = async (props): Promise<Sensor> => {
        return await this.patch({
            extendUrl: `${props.id}/temperature-status`,
            data: { temperatureStatus: props.data }
        });
    };

    patchPressureStatus = async (props): Promise<Sensor> => {
        return await this.patch({
            extendUrl: `${props.id}/pressure-status`,
            data: { pressureStatus: props.data }
        });
    };

    patchRestoreUnmounted = async (id): Promise<Sensor> => {
        return await this.patch({ extendUrl: `${id}/restore` });
    };

    patchAddSensorOnWheel = async (props): Promise<Sensor> => {
        return await this.patch({ extendUrl: 'mount', data: props });
    };

    patchRemoveSensorOnWheel = async (props): Promise<Sensor> => {
        return await this.patch({ extendUrl: 'unmount', data: props });
    };

    patchReplaceSensorOnWheel = async (props: replaceSensorOnWheelProps): Promise<Sensor> => {
        return await this.patch<Sensor, replaceSensorOnWheelProps>({
            extendUrl: 'replace',
            data: {
                newSensorId: props.newSensorId,
                newSensorTypeId: props.newSensorTypeId,
                oldSensorId: props.oldSensorId,
                unmountReason: props.unmountReason
            }
        });
    };

    switchSensorPriorities = async (data: {
        sensorId1: number;
        sensorId2: number;
    }): Promise<SwitchPrioritiesReturnType> => {
        return await this.patch<SwitchPrioritiesReturnType, { sensorId1: number; sensorId2: number }>({
            extendUrl: 'switch-priorities',
            data: data
        });
    };

    unmountSensor = async (data: UnmountSensorProps): Promise<Sensor> => {
        return await this.patch<Sensor, UnmountSensorProps>({
            extendUrl: 'unmount',
            data
        });
    };

    mountSensor = async (data: MountSensorProps): Promise<Sensor> => {
        return await this.patch<Sensor, MountSensorProps>({
            extendUrl: 'mount',
            data
        });
    };

    replaceSensor = async (data: ReplaceSensorProps): Promise<void> => {
        return await this.patch<void, ReplaceSensorProps>({
            extendUrl: 'replace',
            data
        });
    };

    getInspection = (params: InspectionParams): Promise<InspectionResponse> => {
        return this.getByCriteria({ extendUrl: 'inspection2', criteria: params });
    };

    getAttachedSensorVehicle = (vehicleId): Promise<getAttachedSensorVehicleResponse> => {
        return this.getByCriteria({ extendUrl: `vehicle/${vehicleId}` });
    };
}

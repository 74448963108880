import React from 'react';
import { AreasTableViewContent } from './AreasTable.style';
import { AreasTableViewProps } from './AreasTable.type';
import { useTranslation } from 'react-i18next';
import Widget from 'components/Ui/Components/UiWidget';
import Button from 'components/Ui/Components/UiButton';
import { useRecoilValue } from 'recoil';
import UiTable from 'components/Ui/Components/UiTable2';
import AreaApi from 'api/Area';
import { UserInfo } from 'states/global/User';
import { ROLES } from 'variables/index';
import AreaDetails from '../AreaDetails/AreaDetails';
import { AreaFeaturesResponse } from 'models/Area.type';
import { faMap } from '@fortawesome/pro-solid-svg-icons';
import { applyStyleByMode } from 'helpers';
import { checkRole } from 'helpers/authentication';
import { useTheme, useMediaQuery } from '@mui/material';
import UiIcon from 'components/Ui/Components/UiIcon';
import { Theme } from 'states/global/Theme';
import { COLOR_GREY, TEXT_BLACK_OLD, WHITE } from 'components/Ui/colors';
import UiLink from 'components/Ui/Components/UiLink/UiLink';

const areaApi = new AreaApi();

export const AreasTableContent: React.FC<AreasTableViewProps> = (props): JSX.Element => {
    const userInfo = useRecoilValue(UserInfo);
    const { t: translate } = useTranslation();
    const theme = useTheme();
    const showXsSm = useMediaQuery(theme.breakpoints.between('xs', 'sm'));
    const ThemeMode = useRecoilValue(Theme);

    const hidden: string[] = [
        'deviceLastTransmissionFrom',
        'deviceLastTransmissionTo',
        'createdAtFrom',
        'createdAtTo',
        'measuredAtFrom',
        'measuredAtTo',
        'displayLastTransmissionFrom',
        'displayLastTransmissionTo'
    ];
    userInfo.user?.role.name === ROLES.ROLE_TECHNICAL_USER && hidden.push('id');

    const expandContent = (row: AreaFeaturesResponse) => <AreaDetails data={row} />;

    return (
        <AreasTableViewContent data-testid='AreasContent'>
            <Widget
                data-testid={'AreasTableContent-Widget'}
                title={translate('t.areas')}
                avatar={
                    <UiIcon
                        data-testid={'AreasTableContent-UiIcon'}
                        icon={faMap}
                        size='lg'
                        fixedWidth
                        color={applyStyleByMode({
                            styleOld: '#fff',
                            theme: ThemeMode?.mode,
                            light: '#0000008a',
                            dark: WHITE
                        })}
                    />
                }
                content={
                    <UiTable
                        data-testid={'AreasTableContent-UiTable'}
                        fetchFn={areaApi.getAreasTable}
                        exportFn={areaApi.getExport}
                        exportName={'Areas'}
                        columns={props.columns}
                        refresh={false}
                        hiddenColumns={hidden}
                        actionsButton={
                            !checkRole(userInfo.user, [
                                ROLES.ROLE_TECHNICAL_USER,
                                ROLES.ROLE_TYRE_BAY_USER,
                                ROLES.ROLE_ENGINEERING,
                                ROLES.ROLE_BASE_USER
                            ]) ? (
                                <UiLink
                                    $padding='0'
                                    content={
                                        <Button
                                            color='primary'
                                            variant='contained'
                                            skin='success'
                                            style={{ color: '#fff' }}
                                        >
                                            {translate('t.add')}
                                        </Button>
                                    }
                                    testId='Areas-Add-Button'
                                    url={`/area-editor?mode=new`}
                                    color={applyStyleByMode({
                                        styleOld: TEXT_BLACK_OLD,
                                        theme: ThemeMode?.mode,
                                        light: COLOR_GREY,
                                        dark: WHITE
                                    })}
                                />
                            ) : (
                                <></>
                            )
                        }
                        isExpandable={true}
                        maxHeight={showXsSm ? 260 : undefined}
                        expandableContent={expandContent}
                        defaultSortBy={{
                            id: 'name',
                            desc: false
                        }}
                        queryKey='Areas'
                    />
                }
            />
        </AreasTableViewContent>
    );
};

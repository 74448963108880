import { Checkbox, FormControlLabel } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import ViewColumnIcon from '@mui/icons-material/ViewColumn';
import Button from '../../UiButton/UiButton';
import useUserLocalStorageSettings from '../../../../CustomHooks/UserLocalStorageSettings';
import { useTranslation } from 'react-i18next';
import React from 'react';
import * as style from './HideColumns.style';
import * as type from './HideColumns.type';
import Legend from '../../UiLegend/UiLegend';
import { SIZE_BUTTON } from '../../../variables';
import UiIconButton from '../../UiIconButton/UiIconButton';

const HideColumnsContent: React.FC<type.HideColumnsProps> = (props): JSX.Element => {
    const hideableColumns = props.allColumns.filter((column) => column.id !== 'selection');
    const checkedCount = hideableColumns.reduce((acc, val) => acc + (val.isVisible ? 0 : 1), 0);
    const { setUserSettings } = useUserLocalStorageSettings([`table.${props.table.tableKey}Table`]);
    const onlyOneOptionLeft: boolean = checkedCount + 1 >= hideableColumns.length;
    const { t: translate } = useTranslation();

    return (
        <style.HideColumnsContent data-testid='HideColumnsContent'>
            <Legend
                root={
                    <UiIconButton testid='HideColumns-show-button' size={SIZE_BUTTON}>
                        <ViewColumnIcon />
                    </UiIconButton>
                }
                icon={<ViewColumnIcon />}
                width={400}
                headerElements={
                    <>
                        <Button
                            onClick={() => {
                                props.table.setHiddenColumns(props.defaultHiddenColumns || []);
                                setUserSettings(`table.${props.table.tableKey}Table`, {
                                    orderBy: props.table.state.sortBy,
                                    filter: props.table.state.filters,
                                    hiddenColumns: [...(props.defaultHiddenColumns || [])]
                                });
                            }}
                            testid={`HideColumns-${props.table.tableKey}Table-Reset-Button`}
                            title='Reset the column filters to the default values'
                        >
                            {translate('t.reset')}
                        </Button>
                    </>
                }
            >
                <style.HideColumnCover>
                    {hideableColumns
                        .filter((column) => {
                            if (Array.isArray(column.Header)) return column.Header.length > 0;
                            if (typeof column.Header === 'string' && column.Header.trim()) return true;
                            return false;
                        })
                        .map((column) => (
                            <FormControlLabel
                                key={column.id}
                                control={
                                    <Checkbox
                                        value={`${column.id}`}
                                        disabled={column.isVisible && onlyOneOptionLeft}
                                        color='primary'
                                        data-testid={`HideColumns-${props.table.tableKey}Table-Checkbox`}
                                    />
                                }
                                label={<>{column.Header}</>}
                                checked={column.isVisible}
                                onChange={() => {
                                    props.toggleHideColumn(column.id, column.isVisible);
                                }}
                            />
                        ))}
                </style.HideColumnCover>
            </Legend>
        </style.HideColumnsContent>
    );
};

export default HideColumnsContent;

import React from 'react';
import { AlertBodyContainer, AlertByVehicleContainer, AlertContainer, NotResult } from './AlertsList.style';
import { AlertByVehicleViewProps } from './AlertsList.type';
import { Typography } from '@mui/material';
import { FaultySensorReporterState } from 'states/component/FaultySensorReporter';
import { typeAlert as alertType } from '../AlertsListTabManager/AlertsListTabManager.type';
import ContentAlertByVehicle from './ContentAlertsList.view';
import AlertCustom from '../AlertCustom/AlertCustom';
import TagFilter from '../TagFilter/TagFilter';
import { useSetRecoilState } from 'recoil';
import { useTranslation } from 'react-i18next';
import UiLoadingPage from 'components/Ui/Components/UiLoadingPage/UiLoadingPage';
import UiIcon from 'components/Ui/Components/UiIcon/UiIcon';

export const AlertsListContent: React.FC<AlertByVehicleViewProps> = ({
    isLoading,
    alertFilters,
    dataShow,
    typeAlert,
    displayAlertActions,
    isAdmin,
    height,
    showSolveButton,
    showValidateButton,
    displayBody,
    disableRedirect,
    testId,
    RemoveFilterAlertByLevel,
    RemoveFilterByType,
    handleOpenConfigAlertsModal,
    dense,
    sizeAlert,
    alertKey,
    showTab
}): JSX.Element => {
    const { t: translate } = useTranslation();
    const setFaultySensorReporterState = useSetRecoilState(FaultySensorReporterState);
    const dataTestId = dataShow?.length ? 'AlertByVehicleContent' : 'AlertByVehicleContentNoData';

    return isLoading ? (
        <UiLoadingPage size='30px' $marginTop='30px' />
    ) : (
        <AlertByVehicleContainer data-testid={`${dataTestId}-${testId || 'testid'}`}>
            <AlertContainer height={height}>
                {alertFilters && alertFilters.level && typeAlert !== alertType.GPS
                    ? alertFilters.level.map((level, index) => (
                          <TagFilter
                              key={level + '' + index}
                              resetAction={() => RemoveFilterAlertByLevel && RemoveFilterAlertByLevel(level)}
                              chipText={`${translate('t.level')} ${level}`}
                          />
                      ))
                    : ''}
                {alertFilters && alertFilters.type && typeAlert !== alertType.GPS
                    ? alertFilters.type.map((type, index) => (
                          <>
                              <TagFilter
                                  key={type + '' + index}
                                  resetAction={() => RemoveFilterByType && RemoveFilterByType(type)}
                                  chipText={`${translate(`t.${type}`)}`}
                              />
                          </>
                      ))
                    : ''}

                <AlertBodyContainer>
                    {dataShow && !!dataShow.length ? (
                        dataShow.map((alert, index) => {
                            let settingOptions: { title: string; value: number | string; actionGeneral: () => void }[] =
                                [];
                            const getVehicleId = alert.vehicle ? alert.vehicle.id : alert?.wheel?.vehicle?.id ?? 0;
                            const vehicleStatusLink = `${process.env.PUBLIC_URL}/vehicle-status?vehicle=${getVehicleId}&tab=${showTab}`;

                            if (showSolveButton && isAdmin) {
                                settingOptions.push({
                                    title: translate('t.solve_alert'),
                                    value: alert.id,
                                    actionGeneral: () => {
                                        handleOpenConfigAlertsModal && handleOpenConfigAlertsModal(alert.id);
                                    }
                                });
                                if (isAdmin) {
                                    settingOptions.push({
                                        title: translate('t.false_alert'),
                                        value: alert.id,
                                        actionGeneral: () => {
                                            handleOpenConfigAlertsModal && handleOpenConfigAlertsModal(alert.id, 1);
                                        }
                                    });
                                }
                            }
                            if (showValidateButton && isAdmin) {
                                settingOptions.push({
                                    title: translate('t.validate_alert'),
                                    value: alert.id,
                                    actionGeneral: () => {
                                        setFaultySensorReporterState({
                                            sensorId: alert.sensor.id,
                                            vehicleName: alert.vehicle?.name || '',
                                            vehicleId: alert.vehicle.id,
                                            sensorName: alert.sensor.serialNumberHex,
                                            wheelPosition: alert.wheel.customPosition,
                                            internalOnVehicle: alert.vehicle.internalSensor,
                                            isExternal: alert.sensor.sensorType.id === 2,
                                            allowReportLeakAlert: true,
                                            showOnlyGraph: true
                                        });
                                    }
                                });
                            }

                            return (
                                <AlertCustom
                                    dense={dense}
                                    level={alert.notification ? alert.notification?.level || 1 : 1}
                                    isTempPredAlert={alert.notification?.type === 'temperature_prediction'}
                                    {...(alert.notification?.type === 'temperature_prediction' &&
                                    alert.vehiclePrediction
                                        ? {
                                              dataForPredictionGraph: {
                                                  vehicleName: alert.vehicle.name,
                                                  wheelPredictedId: alert.vehiclePrediction.id,
                                                  vehicleId: alert.vehicle.id
                                              }
                                          }
                                        : {})}
                                    chip={
                                        !(
                                            typeAlert == alertType.GPS ||
                                            typeAlert == alertType.Sensor ||
                                            typeAlert == alertType.VehicleEvent ||
                                            alert.notification?.type == 'battery_voltage' ||
                                            alert.notification?.type == 'emmc'
                                        )
                                    }
                                    content={
                                        <ContentAlertByVehicle
                                            alert={alert}
                                            level={alert.notification ? alert.notification?.level : 1}
                                            dense={dense}
                                            typeAlertContent={
                                                !(alert?.notification && alert?.notification?.type == 'battery_voltage')
                                                    ? typeAlert
                                                    : alertType.Sensor
                                            }
                                            displayBody={displayBody}
                                        />
                                    }
                                    link={vehicleStatusLink}
                                    key={`${alertKey}-${index}`}
                                    data-testid={`${alertKey}-${index}`}
                                    footer={alert.measuredAt}
                                    actions={
                                        !(alert?.notification && alert?.notification?.type == 'battery_voltage') &&
                                        displayAlertActions &&
                                        isAdmin
                                            ? settingOptions
                                            : undefined
                                    }
                                    displayBody={displayBody}
                                    disableRedirect={disableRedirect}
                                    sizeAlert={sizeAlert}
                                />
                            );
                        })
                    ) : (
                        <NotResult>
                            <UiIcon icon={['fas', 'circle-check']} size={'2x'} />
                            <Typography>{translate('t.no_alerts')}</Typography>
                        </NotResult>
                    )}
                </AlertBodyContainer>
            </AlertContainer>
        </AlertByVehicleContainer>
    );
};

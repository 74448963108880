import React, { useMemo } from 'react';
import {
    BESLabel,
    BESText,
    BatteryWrapper,
    GpsIconWrapper,
    HeaderWrapper,
    HubWrapper,
    MaintenanceIcon,
    NoVehicleIcon,
    ShutdownIconWrapper,
    SignalStatusWrapper,
    StatusIcon,
    TitleWrapper,
    VehicleIconWrapper,
    VehicleListDiv,
    VehicleStatusGridViewContent,
    VehicleTitle,
    VehicleWrapper
} from './VehicleStatusGrid.style';
import { AlertsToDisplay, VehicleBoxProps, VehicleStatusGridViewProps } from './VehicleStatusGrid.type';
import GpsIndicator from '../GpsIndicator/GpsIndicator';
import HubBatteryIndicator from '../../../HubBatteryIndicator/HubBatteryIndicator';
import { applyStyleByMode, getBesLabel, getBesRank, isVehicleTyreBayHub } from 'helpers';
import { Theme } from 'states/global/Theme';
import { useRecoilValue } from 'recoil';
import UiIcon from 'components/Ui/Components/UiIcon/UiIcon';
import { SECONDARY_DARK_MODE, WHITE } from 'components/Ui/colors';
import HubConnectionIndicator from '../../../HubConnectionIndicator/HubConnectionIndicator';
import { useTranslation } from 'react-i18next';
import { Typography } from '@mui/material';
import { DateTime } from 'luxon';
import { useNavigate } from 'react-router-dom';
import { getImgSource } from 'helpers/image';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSlash as faSlashRegular } from '@fortawesome/pro-regular-svg-icons';
import { faThermometerThreeQuarters } from '@fortawesome/pro-solid-svg-icons';
import {
    UiHighPressureIcon,
    UiLowPressureIcon
} from 'components/Vehicle/VehicleStats/Components/VehicleStatsRow/VehicleStatsRow.style';
import { CustomerSettings } from 'states/global/CustomerSettings';
import TemperaturePredictionPeriodIcon from 'components/Mixs/TemperaturePredictionPeriodIcon';

const VehicleBox: React.FC<VehicleBoxProps> = (props): JSX.Element => {
    const ThemeMode = useRecoilValue(Theme);
    const displayAlertsIcons: AlertsToDisplay = props.displayAlerts(props.data.alert);
    const customerSettings = useRecoilValue(CustomerSettings);
    const { t: translate } = useTranslation();
    const navigate = useNavigate();
    const isSelected = useMemo(
        () => props.vehicleId === props.data.vehicle.id,
        [props.vehicleId, props.data.vehicle.id]
    );

    return (
        <VehicleWrapper
            selected={isSelected}
            alertLevel={props.getHighestAlert(props.data.alert)}
            onClick={() => {
                if (!isSelected) {
                    const url: string = location.href;

                    !props.isLoadingVehicle && props.setVehicleId(props.data.vehicle.id);
                    url.includes('vehicle-status')
                        ? navigate(`?id=${props.data.vehicle.id}`, { replace: true })
                        : navigate(`vehicle-status?id=${props.data.vehicle.id}`, { replace: true });
                }
            }}
            title={props.data.device?.id ? `${props.data.device.id}` : `${translate('t.no_hub_attached')}`}
            $disableVehicleSelection={props.isLoadingVehicle}
            data-testid='VehicleStatus-SelectVehicle-Grid-Button'
        >
            <HeaderWrapper>
                <SignalStatusWrapper data-testid='VehicleBoxgrid-SignalStatusWrapper-id'>
                    <HubConnectionIndicator
                        lastTimeConnected={DateTime.fromISO(props.data.vehicle.vehicleInfo.measuredAt)}
                        data-testid='VehicleBoxgrid-HubConnectionIndicator-id'
                        connectionType={'wifi'}
                    />
                </SignalStatusWrapper>

                {props.data.vehicle.maintenance && (
                    <MaintenanceIcon data-testid='VehicleBoxgrid-MaintenanceIcon-id'>
                        <UiIcon
                            icon={['fas', 'wrench']}
                            size='sm'
                            color={applyStyleByMode({
                                styleOld: '#383838',
                                theme: ThemeMode?.mode,
                                light: '#0000008a',
                                dark: WHITE
                            })}
                        />
                    </MaintenanceIcon>
                )}
                {/* Temporary disabled for BE */}
                {/* {props.data.vehicle.vehicleInfo.parkingTime > 0 && (
                    <ParkedIcon data-testid='VehicleBoxgrid-ParkedIcon-id'>
                        <UiIcon
                            icon={['fas', 'parking']}
                            size='sm'
                            fixedWidth
                            color={applyStyleByMode({
                                styleOld: '#000',
                                theme: ThemeMode?.mode,
                                light: SECONDARY_DARK_MODE,
                                dark: WHITE
                            })}
                        />
                    </ParkedIcon>
                )} */}
                {props.data.device?.id && (
                    <BatteryWrapper data-testid='VehicleBoxgrid-BatteryWrapper-id'>
                        <HubBatteryIndicator
                            battery={props.data.vehicle.vehicleInfo.battery}
                            powerSource={props.data.vehicle.vehicleInfo.powerSource}
                        />
                    </BatteryWrapper>
                )}
                {!props.data.device?.id && (
                    <HubWrapper data-testid='VehicleBoxgrid-HubWrapper-id'>
                        <UiIcon
                            icon={['fas', 'window-close']}
                            size='sm'
                            color={applyStyleByMode({
                                styleOld: '#383838',
                                theme: ThemeMode?.mode,
                                light: '#0000008a',
                                dark: WHITE
                            })}
                        />
                    </HubWrapper>
                )}
                {props.data.device?.deviceInfo.shutdown && (
                    <ShutdownIconWrapper data-testid='VehicleBoxgrid-ShutdownIconWrapper-id'>
                        <UiIcon
                            icon={['fas', 'power-off']}
                            size='sm'
                            color={applyStyleByMode({
                                styleOld: '#383838',
                                theme: ThemeMode?.mode,
                                light: '#0000008a',
                                dark: WHITE
                            })}
                        />
                    </ShutdownIconWrapper>
                )}

                {props.data.vehicle.genericTemperatureAlert && displayAlertsIcons.temperature && (
                    <StatusIcon
                        $hide={!displayAlertsIcons.temperature}
                        $temperatureIcon
                        data-testid='VehicleBoxgrid-StatusIcon-temperature-id'
                    >
                        <UiIcon
                            icon={['fas', 'thermometer-three-quarters']}
                            size='sm'
                            fixedWidth
                            color={applyStyleByMode({
                                styleOld: '#FF1616',
                                theme: ThemeMode?.mode,
                                dark: WHITE,
                                light: '#FF1616'
                            })}
                        />
                    </StatusIcon>
                )}
                {!props.data.vehicle.genericTemperatureAlert &&
                    customerSettings?.temperature_prediction &&
                    props.data.vehicle.predictionTemperature && (
                        <StatusIcon $temperatureIcon data-testid='VehicleBoxgrid-StatusIcon-temperature-disabled-id'>
                            <span className='fa-layers fa-fw'>
                                <FontAwesomeIcon
                                    icon={faSlashRegular}
                                    fontSize='16px'
                                    color={applyStyleByMode({
                                        styleOld: '#FF1616',
                                        theme: ThemeMode?.mode,
                                        dark: WHITE,
                                        light: '#787878'
                                    })}
                                />
                                <FontAwesomeIcon
                                    icon={faThermometerThreeQuarters}
                                    fixedWidth
                                    fontSize='16px'
                                    color={applyStyleByMode({
                                        styleOld: '#FF1616',
                                        theme: ThemeMode?.mode,
                                        dark: WHITE,
                                        light: '#787878'
                                    })}
                                />
                            </span>
                        </StatusIcon>
                    )}
                {displayAlertsIcons.highPressure && (
                    <StatusIcon
                        $pressureIcon
                        $hide={!displayAlertsIcons.highPressure}
                        data-testid='VehicleBoxgrid-StatusIcon-highpressure-id'
                    >
                        <UiHighPressureIcon color='#FFF' $paddingTop />
                    </StatusIcon>
                )}
                {displayAlertsIcons.lowPressure && (
                    <StatusIcon
                        $pressureIcon
                        $hide={!displayAlertsIcons.lowPressure}
                        data-testid='VehicleBoxgrid-StatusIcon-lowpressure-id'
                    >
                        <UiLowPressureIcon $paddingTop />
                    </StatusIcon>
                )}
                {displayAlertsIcons.temperaturePrediction && (
                    <StatusIcon
                        $hide={!displayAlertsIcons.temperaturePrediction}
                        data-testid='VehicleBoxgrid-StatusIcon-prediction-id'
                    >
                        <UiIcon
                            icon={['fas', 'chart-mixed-up-circle-currency']}
                            size='sm'
                            fixedWidth
                            color={applyStyleByMode({
                                styleOld: '#FF1616',
                                theme: ThemeMode?.mode,
                                dark: WHITE,
                                light: '#FF1616'
                            })}
                        />
                    </StatusIcon>
                )}
                {props.data.vehicle.predictionTemperature && (
                    <StatusIcon
                        $hide={!props.data.vehicle.predictionTemperature}
                        data-testid='VehicleBoxgrid-StatusIcon-prediction-period-id'
                    >
                        <TemperaturePredictionPeriodIcon
                            time={props.data.vehicle.lastTemperaturePredictedAt}
                            predictionTemperature={props.data.vehicle.predictionTemperature}
                            wheels={props.data.vehicle.wheels}
                        />
                    </StatusIcon>
                )}

                {props.data.iconAlert.length > 0 && (
                    <UiIcon
                        data-testid='VehicleBoxgrid-StatusIcon-system-alerts-id'
                        icon={['fas', 'exclamation-triangle']}
                        size='sm'
                        fixedWidth
                        color={applyStyleByMode({
                            theme: ThemeMode?.mode,
                            dark: WHITE,
                            light: SECONDARY_DARK_MODE
                        })}
                    />
                )}

                <GpsIconWrapper data-testid='VehicleBoxgrid-GpsIconWrapper-id'>
                    <GpsIndicator
                        powerSource={props.data.vehicle.vehicleInfo.powerSource}
                        deviceId={props.data.device?.id || null}
                        lastGpsMeasuredAt={DateTime.fromISO(props.data.vehicle.vehicleInfo.lastGpsMeasuredAt)}
                        maintenance={props.data.vehicle.maintenance}
                        outOfService={props.data.vehicle.outOfService}
                        measuredAt={DateTime.fromISO(props.data.vehicle.vehicleInfo.measuredAt)}
                    />
                </GpsIconWrapper>
            </HeaderWrapper>
            <VehicleIconWrapper
                $outOfService={props.data.vehicle.outOfService}
                data-testid='VehicleBoxgrid-VehicleIconWrapper-id'
            >
                {customerSettings.mdd_bes && props.data.highestBes > 0 && (
                    <BESLabel
                        rank={getBesRank(customerSettings.mdd_bes_display_name.toUpperCase(), props.data.highestBes)}
                        data-testid='VehicleBoxgrid-BES-UiIcon-id'
                    >
                        <BESText>
                            {getBesLabel(
                                customerSettings.mdd_bes_display_name.toUpperCase(),
                                props.data.highestBes,
                                translate
                            )}
                        </BESText>
                    </BESLabel>
                )}

                {props.data.vehicle.icon ? (
                    // Temporary solution, remove when BE repairs icon in v1/webtrack/vehicle/status
                    <img
                        width='40px'
                        alt='vehicle'
                        src={getImgSource(
                            `${
                                isVehicleTyreBayHub(props.data.vehicle.vehicleConfigurationId)
                                    ? 'hub/48x48/tyre_bay_hub.png'
                                    : props.data.vehicle.icon
                            }`
                        )}
                    />
                ) : (
                    <NoVehicleIcon data-testid='VehicleBoxgrid-novehicle-UiIcon-id'>
                        <UiIcon
                            icon={['fas', 'ban']}
                            size='sm'
                            color={applyStyleByMode({
                                styleOld: '#383838',
                                theme: ThemeMode?.mode,
                                light: '#0000008a',
                                dark: WHITE
                            })}
                        />
                    </NoVehicleIcon>
                )}
            </VehicleIconWrapper>

            <TitleWrapper>
                <VehicleTitle title={props.data.vehicle.name} alertLevel={props.getHighestAlert(props.data.alert)}>
                    {props.data.vehicle.name}
                </VehicleTitle>
            </TitleWrapper>
        </VehicleWrapper>
    );
};

const createList = (props: VehicleStatusGridViewProps): JSX.Element => {
    const vehiclesList: JSX.Element[] =
        props.data?.vehicleStatus.map((vehicle, index) => (
            <VehicleBox
                data={vehicle}
                key={index}
                isLoadingVehicle={props.isLoadingVehicle}
                setVehicleId={props.setVehicleId}
                vehicleId={props.vehicleId}
                getHighestAlert={props.getHighestAlert}
                displayAlerts={props.displayAlerts}
            />
        )) || [];

    return <VehicleListDiv>{vehiclesList}</VehicleListDiv>;
};

export const VehicleStatusGridContent: React.FC<VehicleStatusGridViewProps> = (props): JSX.Element => {
    const { t: translate } = useTranslation();

    return (
        <VehicleStatusGridViewContent data-testid='VehicleStatusGridContent'>
            {props.data?.vehicleStatus.length ? (
                createList(props)
            ) : (
                <Typography align='center' marginTop={1} variant='subtitle1'>
                    {translate('t.there_no_data')}
                </Typography>
            )}
        </VehicleStatusGridViewContent>
    );
};

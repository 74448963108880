import React, { useCallback, useEffect, useRef, useState, useMemo } from 'react';
import UiIcon from 'components/Ui/Components/UiIcon/UiIcon';
import { faStopwatch } from '@fortawesome/pro-regular-svg-icons';
import { LeftDiv, StyledDiv, TimerLinearProgress, TimerText, TimerTextContent } from '../PredictedGraph.style';
import { useTranslation } from 'react-i18next';
import { TimerProps } from '../PredictedGraph.type';
import useConverter from 'components/CustomHooks/Converter/Converter';
import { Typography } from '@mui/material';
import { ALERT_LEVEL_2_PRED_TEMP_DARK } from 'components/Ui/colors';
import { useRecoilValue } from 'recoil';
import { CustomerSettings } from 'states/global/CustomerSettings';

const Timer = ({ criticalTemperature, remainingHours, alertLevel }: TimerProps): JSX.Element => {
    const { t: translate } = useTranslation();
    const { convertType, fromServerToUserUnit } = useConverter();
    const intervalRef = useRef<NodeJS.Timeout>();
    const [percentage, setPercentage] = useState<number>(0);
    const hideImmediateAction = useMemo(() => remainingHours === '00:00:00', [remainingHours]);
    const customerSettings = useRecoilValue(CustomerSettings);

    const getPercentage = useCallback(() => {
        if (remainingHours === '00:00:00') {
            setPercentage(100);
        } else {
            const remainingHoursSplit = remainingHours.split(':');
            const remainingHoursInSeconds =
                Number(remainingHoursSplit[0]) * 60 * 60 +
                Number(remainingHoursSplit[1]) * 60 +
                Number(remainingHoursSplit[2]);

            setPercentage(
                100 - (remainingHoursInSeconds / (customerSettings.temperature_prediction_hours * 60 * 60)) * 100
            );
        }
    }, [remainingHours, customerSettings]);

    const getDefineInterval = useCallback(() => {
        getPercentage();
        if (intervalRef.current) {
            clearInterval(intervalRef.current);
        }
        intervalRef.current = setInterval(() => getPercentage(), 1000);
    }, [getPercentage]);

    useEffect(() => {
        getDefineInterval();
        return () => {
            setPercentage(0);
            if (intervalRef.current) {
                clearInterval(intervalRef.current);
            }
        };
    }, [getDefineInterval]);

    return (
        <StyledDiv data-testid='timer'>
            <TimerTextContent>
                <LeftDiv>
                    <div>
                        <UiIcon icon={faStopwatch} size={'sm'} />
                    </div>
                    <TimerText>
                        {`${translate('t.time_for_temperature')} ${fromServerToUserUnit({
                            value: criticalTemperature,
                            type: convertType.temperature,
                            fixed: 1,
                            displayUnits: true,
                            displayIfEmpty: '-'
                        })}`}
                    </TimerText>
                    {hideImmediateAction && (
                        <Typography fontWeight='bold' variant='body1' color={ALERT_LEVEL_2_PRED_TEMP_DARK}>
                            <b>- {translate('t.immediate_action')}!</b>
                        </Typography>
                    )}
                </LeftDiv>
            </TimerTextContent>
            <div>
                <TimerLinearProgress variant='determinate' value={percentage} alertLevel={alertLevel} />
            </div>
        </StyledDiv>
    );
};

export default Timer;

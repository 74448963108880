import {
    useColumnOrder,
    useExpanded,
    useFilters,
    useFlexLayout,
    useGlobalFilter,
    useGroupBy,
    usePagination,
    useResizeColumns,
    useSortBy,
    useTable
} from 'react-table';
import React, { useEffect, useMemo, useState } from 'react';
import ColumnFilter from './ColumnFilter';
import { useRecoilState } from 'recoil';
import { TableActions, TableAtom, TableDispatcher } from 'states/component/Table';
import { UiTableContent } from './UiTable.view';
import { ExportState, FilterProps, TableInstanceProps } from './UiTable.type';
import UiIcon from 'components/Ui/Components/UiIcon/UiIcon';
import { IconButton } from '@mui/material';
import useUserLocalStorageSettings, {
    useUserLocalStorageSettingsTable
} from '../../../CustomHooks/UserLocalStorageSettings';
import { Success } from '../../../Popup/Popup';
import { useTranslation } from 'react-i18next';
import ReactToPrint from 'react-to-print';
import { createSCV } from 'helpers';
import { Column } from 'models/Table.type';
import UiIconButton from '../UiIconButton/UiIconButton';

export const TableInstance: React.FC<TableInstanceProps> = (props): JSX.Element => {
    const [tableState, setTableState] = useRecoilState(TableAtom(`${props.queryKey}-Table`));
    const { setUserSettings } = useUserLocalStorageSettings([`table.${props.queryKey}Table`]);
    const { t: translate } = useTranslation();
    const tableDispatch = TableDispatcher(tableState, setTableState);
    const [currentFilters, setCurrentFilters] = useState<[] | FilterProps[]>(tableState.queryPageFilter);
    const [tableFlag, setTableFlag] = useState<boolean>(false);
    const [exportState, setExportState] = useState<ExportState>(ExportState.SET_NONE);
    const componentToPrintRef = React.useRef<React.ReactInstance | null>(null);
    const buttonToPrintRef = React.useRef<HTMLButtonElement>(null);
    const [showScroll, setShowScroll] = React.useState(true);
    const [loadingUnmount, setLoadingUnmount] = React.useState(false);
    const { getUserSettingTable } = useUserLocalStorageSettingsTable();

    const reactToPrintContent = React.useCallback(() => {
        return componentToPrintRef.current;
    }, [componentToPrintRef]);

    useEffect(() => {
        if (!showScroll && buttonToPrintRef.current) {
            buttonToPrintRef.current.click();

            setLoadingUnmount(true);
        }
        if (showScroll) setLoadingUnmount(false);
    }, [showScroll]);

    const copyTableData = (): void => {
        if (!props.isLoadingExportData) {
            setExportState(ExportState.SET_COPY);
            props.exportRefetch();
        }
    };

    const downloadTableData = (): void => {
        if (!props.isLoadingExportData) {
            setExportState(ExportState.SET_CSV);
            props.exportRefetch();
        }
    };

    useEffect(() => {
        if (props.exportData?.length && !props.isLoadingExportData) {
            if (exportState === ExportState.SET_CSV) {
                createSCV(props.exportData, props.exportName ?? '');
                setExportState(ExportState.SET_NONE);
            } else if (exportState === ExportState.SET_COPY) {
                navigator.clipboard.writeText(props.exportData ?? ' ');
                Success({
                    text: translate('t.copied')
                });
                setExportState(ExportState.SET_NONE);
            }
        }
    }, [props.isLoadingExportData]);

    const defaultColumn = useMemo(() => {
        return {
            Filter: ColumnFilter
        };
    }, []);

    const expandableColumn: Column<any> = {
        id: 'expander',
        width: 5,
        Header: ({ rows, toggleAllRowsExpanded }) => {
            const handleToggleAllRows = () => {
                const areAllRowsExpanded = rows.every((row) => row.isExpanded);
                toggleAllRowsExpanded(!areAllRowsExpanded);
            };

            return (
                <button onClick={handleToggleAllRows} style={{ cursor: 'pointer' }}>
                    <UiIcon onClick={handleToggleAllRows} icon={['fas', 'chevron-up']} /> :{' '}
                    <UiIcon icon={['fas', 'chevron-down']} />
                </button>
            );
        },
        isFoldable: true,
        Cell: ({ row }) => (
            <UiIconButton easyReadingValue='medium' aria-label='expand row' {...row.getToggleRowExpandedProps()}>
                {row.isExpanded ? <UiIcon icon={['fas', 'chevron-up']} /> : <UiIcon icon={['fas', 'chevron-down']} />}
            </UiIconButton>
        )
    };

    props.isExpandable ? props.columns.unshift(expandableColumn) : props.columns;

    const tableInstance = useTable(
        {
            columns: props.columns,
            data: props.data,
            initialState: {
                pageIndex: tableState.queryPageIndex,
                pageSize: tableState.queryPageSize,
                sortBy: tableState.queryPageSortBy,
                filters: tableState.queryPageFilter,
                hiddenColumns: !getUserSettingTable(`table.${props.queryKey}Table`)?.hiddenColumns
                    ? props.hiddenColumns || []
                    : getUserSettingTable(`table.${props.queryKey}Table`)?.hiddenColumns
            },
            manualPagination: true,
            defaultColumn,
            pageCount: props.pageCount,
            autoResetPage: false,
            manualSortBy: true,
            manualFilters: true
        },
        useColumnOrder,
        useFilters,
        useGroupBy,
        useFilters,
        useGlobalFilter,
        useSortBy,
        useExpanded,
        useFlexLayout,
        usePagination,
        useResizeColumns
    );

    const handleChangeRowsPerPage = (event): void => {
        tableInstance.setPageSize(+event.target.value);
        tableDispatch({
            type: TableActions.PAGE_SIZE_CHANGED,
            payload: event.target.value
        });
        setUserSettings(`table.${props.queryKey}Table`, {
            filter: tableState.queryPageFilter,
            orderBy: tableState.queryPageSortBy,
            pageSize: +event.target.value
        });
    };

    useEffect(() => {
        if (tableInstance.state.pageIndex !== tableState.queryPageIndex) {
            tableDispatch({
                type: TableActions.PAGE_CHANGED,
                payload: tableInstance.state.pageIndex
            });
            return;
        } else if (tableInstance.state.sortBy !== tableState.queryPageSortBy) {
            tableDispatch({
                type: TableActions.PAGE_SORT_CHANGED,
                payload: tableInstance.state.sortBy
            });
        } else {
            tableDispatch({
                type: TableActions.PAGE_FILTER_CHANGED,
                payload: currentFilters
            });
        }
        tableInstance.gotoPage(0);
    }, [tableInstance.state.pageIndex, tableInstance.state.pageSize, tableInstance.state.sortBy, currentFilters]);

    useEffect(() => {
        if (tableState.queryPageFilter !== currentFilters) {
            setCurrentFilters(tableState.queryPageFilter);
            tableInstance.setAllFilters(tableState.queryPageFilter);
        }

        if (!tableFlag && tableState.queryPageFilter.length > 0) {
            tableInstance.setFilter(tableState.queryPageFilter[0].id, {
                name: tableState.queryPageFilter[0].value.name,
                value: tableState.queryPageFilter[0].value.value,
                humanValue: tableState.queryPageFilter[0].value.humanValue
            });
            setTableFlag(true);
        }
    }, [tableState.queryPageFilter]);

    useEffect(() => {
        if (
            getUserSettingTable(`table.${props.queryKey}Table`) &&
            getUserSettingTable(`table.${props.queryKey}Table`)?.orderBy
        ) {
            tableInstance.setSortBy(getUserSettingTable(`table.${props.queryKey}Table`)?.orderBy);
        }

        if (
            getUserSettingTable(`table.${props.queryKey}Table`) &&
            getUserSettingTable(`table.${props.queryKey}Table`)?.hiddenColumns
        ) {
            tableInstance.setHiddenColumns(getUserSettingTable(`table.${props.queryKey}Table`)?.hiddenColumns);
        } else {
            setUserSettings(`table.${props.queryKey}Table`, {
                orderBy: tableInstance.state.sortBy,
                filter: tableInstance.state.filters,
                hiddenColumns: tableInstance.state.hiddenColumns,
                pageSize: tableState.queryPageSize
            });
        }
    }, []);

    useEffect(() => {
        if (
            getUserSettingTable(`table.${props.queryKey}Table`) &&
            getUserSettingTable(`table.${props.queryKey}Table`)?.hiddenColumns &&
            tableInstance.state.hiddenColumns != getUserSettingTable(`table.${props.queryKey}Table`)?.hiddenColumns
        ) {
            setUserSettings(`table.${props.queryKey}Table`, {
                ...getUserSettingTable(`table.${props.queryKey}Table`),

                hiddenColumns: tableInstance.state.hiddenColumns
            });
        }
    }, [tableInstance.state.hiddenColumns]);

    return (
        <>
            <div style={{ display: 'none' }}>
                <ReactToPrint
                    trigger={() => <button ref={buttonToPrintRef}></button>}
                    content={reactToPrintContent}
                    onAfterPrint={() => setShowScroll(true)}
                    documentTitle={`${props.exportName ?? 'Table'} Bridgestone iTrack - Mining TPMS 24/7`}
                />
            </div>
            <UiTableContent
                {...props}
                tableInstance={tableInstance}
                currentFilters={currentFilters}
                setCurrentFilters={setCurrentFilters}
                isExpandable={props.isExpandable}
                expandableContent={props.expandableContent}
                maxHeight={props.maxHeight}
                downloadTableData={downloadTableData}
                copyTableData={copyTableData}
                export={!!props.exportFn}
                setShowScroll={setShowScroll}
                handleChangeRowsPerPage={handleChangeRowsPerPage}
                componentRef={componentToPrintRef}
                showScroll={showScroll}
                loadingUnmount={loadingUnmount}
                tableKey={tableState.tableKey}
            />
        </>
    );
};

import React, { useCallback, useMemo } from 'react';
import { ActionHederText, HeaderCell, TableCellEl, TableHeadEl } from './UiTableHeader.style';
import { HeaderGroupFilterOption, UiTableHeaderProps } from './UiTableHeader.type';
import { useTranslation } from 'react-i18next';
import { Box, TableRow, TableSortLabel } from '@mui/material';
import UiTableFilterCell from '../UiTableFilterCell/UiTableFilterCell';
import UiTableApplyFilter from '../UiTableApplyFilterBtn/UiTableApplyFilterBtn';
import UiTableHeaderCheckbox from '../UiTableHeaderCheckbox/UiTableHeaderCheckbox';
import { ResizeSeparator } from '../Components/UiTable2/UiTable.style';
import useUserLocalStorageSettings, {
    useUserLocalStorageSettingsTable
} from 'components/CustomHooks/UserLocalStorageSettings';

const UiTableHeaderContent: React.FC<UiTableHeaderProps> = (props): JSX.Element => {
    const { t: translate } = useTranslation();
    const localStorageTableKey = useMemo(() => `table.${props.queryKey}Table`, [props.queryKey]);
    const { setUserSettings } = useUserLocalStorageSettings([localStorageTableKey]);
    const { getUserSettingTable } = useUserLocalStorageSettingsTable();

    const TableHeader = useCallback(() => {
        return (
            <>
                {props.headerGroups?.map((headerGroup, groupIndex) => {
                    return (
                        <React.Fragment key={groupIndex}>
                            <TableRow data-testid='headerRow' {...headerGroup.getHeaderGroupProps()}>
                                {headerGroup.headers.map((column: HeaderGroupFilterOption, headerIndex) => {
                                    return (
                                        <>
                                            {props.useColumnCheckbox && headerIndex === 0 && <UiTableHeaderCheckbox />}
                                            {props.useColumnAction?.position === headerIndex && headerIndex === 0 && (
                                                <React.Fragment key={`${headerIndex}ActionHeader`}>
                                                    <TableCellEl
                                                        {...column.getHeaderProps()}
                                                        data-testid={`actionHeaderColumn`}
                                                    >
                                                        <ActionHederText>{translate('t.action')}</ActionHederText>
                                                        <Box>
                                                            <UiTableApplyFilter queryKey={props.queryKey} />
                                                        </Box>
                                                    </TableCellEl>
                                                </React.Fragment>
                                            )}
                                            <React.Fragment key={headerIndex}>
                                                <TableCellEl
                                                    {...column.getHeaderProps()}
                                                    data-testid={`${column.id}HeaderColumn`}
                                                >
                                                    {column.canSort && column.id !== 'selection' ? (
                                                        <TableSortLabel
                                                            active={column.isSorted}
                                                            direction={column.isSortedDesc ? 'desc' : 'asc'}
                                                            style={column.getSortByToggleProps().style}
                                                            title={column.getSortByToggleProps().title}
                                                            onClick={(e) => {
                                                                column.getSortByToggleProps().onClick?.(e);
                                                                setTimeout(() => {
                                                                    column.isSortedDesc === undefined
                                                                        ? setUserSettings(localStorageTableKey, {
                                                                              ...getUserSettingTable(
                                                                                  localStorageTableKey
                                                                              ),
                                                                              orderBy: []
                                                                          })
                                                                        : setUserSettings(localStorageTableKey, {
                                                                              ...getUserSettingTable(
                                                                                  localStorageTableKey
                                                                              ),
                                                                              orderBy: [
                                                                                  {
                                                                                      id: column.id,
                                                                                      desc: column.isSortedDesc
                                                                                  }
                                                                              ]
                                                                          });
                                                                }, 1000);
                                                            }}
                                                        >
                                                            <HeaderCell>
                                                                <>{column.Header}</>
                                                            </HeaderCell>
                                                        </TableSortLabel>
                                                    ) : (
                                                        <HeaderCell>
                                                            <>{column.Header}</>
                                                        </HeaderCell>
                                                    )}

                                                    {!column.disableFilters && props.useColumnFiltering && (
                                                        <UiTableFilterCell
                                                            accessor={column.id}
                                                            filterProps={column.filterProps}
                                                            queryKey={props.queryKey}
                                                        />
                                                    )}

                                                    {column.canResize && (
                                                        <ResizeSeparator
                                                            {...column.getResizerProps()}
                                                            className={`${column.isResizing ? 'isResizing' : ''}`}
                                                        />
                                                    )}
                                                </TableCellEl>
                                            </React.Fragment>
                                            {props.useColumnAction?.position === headerIndex && headerIndex !== 0 && (
                                                <React.Fragment key={`${headerIndex}ActionHeader`}>
                                                    <TableCellEl
                                                        {...column.getHeaderProps()}
                                                        data-testid={`actionHeaderColumn`}
                                                    >
                                                        <HeaderCell>{translate('t.action')}</HeaderCell>
                                                        <Box>
                                                            <UiTableApplyFilter queryKey={props.queryKey} />
                                                        </Box>
                                                    </TableCellEl>
                                                </React.Fragment>
                                            )}
                                        </>
                                    );
                                })}
                            </TableRow>
                        </React.Fragment>
                    );
                })}
            </>
        );
    }, [props.headerGroups]);

    return (
        <TableHeadEl data-testid='UiTableHeaderContent'>
            <TableHeader />
        </TableHeadEl>
    );
};

export default UiTableHeaderContent;

import React from 'react';
import { useRecoilValue } from 'recoil';
import { BatchesTableContainer } from './BatchesTable.style';
import { BatchesTableViewPropsView } from './BatchesTable.type';
import UiTable from 'components/Ui/Components/UiTable2/UiTable';
import { useTranslation } from 'react-i18next';
import DeviceUpdateBatch from 'api/DeviceUpdateBatch';
import Widget from 'components/Ui/Components/UiWidget/UiWidget';
import { applyStyleByMode } from 'helpers';
import { faDatabase } from '@fortawesome/pro-solid-svg-icons';
import UiIcon from 'components/Ui/Components/UiIcon';
import { WHITE } from 'components/Ui/colors';
import { Theme } from 'states/global/Theme';

const deviceUpdateBatchApi = new DeviceUpdateBatch();

export const BatchesTableContent: React.FC<BatchesTableViewPropsView> = (props): JSX.Element => {
    const { t: translate } = useTranslation();
    const ThemeMode = useRecoilValue(Theme);
    const hidden: string[] = ['createdAtFrom', 'createdAtTo', 'finishedAtFrom', 'finishedAtTo'];

    return (
        <>
            <BatchesTableContainer data-testid='BatchesTableContent'>
                <Widget
                    data-testid='BatchesTableContent'
                    avatar={
                        <UiIcon
                            icon={faDatabase}
                            size='lg'
                            fixedWidth
                            color={applyStyleByMode({
                                styleOld: '#fff',
                                theme: ThemeMode?.mode,
                                light: '#0000008a',
                                dark: WHITE
                            })}
                        />
                    }
                    title={translate('t.batches')}
                    loading={false}
                    content={
                        <UiTable
                            columns={props.columns}
                            fetchFn={deviceUpdateBatchApi.getBatchTable}
                            hiddenColumns={hidden}
                            hiddenFilterAction={true}
                            refresh={30000}
                            defaultSortBy={{
                                id: 'id',
                                desc: false
                            }}
                            queryKey='batchesHub'
                        />
                    }
                ></Widget>
            </BatchesTableContainer>
        </>
    );
};

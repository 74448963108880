import React from 'react';
import { Chip } from '@mui/material';
import * as type from './ChipBar.type';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';
import { UserInfo } from 'states/global/User';
import { ChipBarContent, ContainerFilter, TitleFilter } from './ChipBar.style';
import { VARIANT_CHIP } from 'components/Ui/variables';
import useUserLocalStorageSettings, {
    useUserLocalStorageSettingsTable
} from 'components/CustomHooks/UserLocalStorageSettings';
import { DateTime } from 'luxon';
import UiIcon from 'components/Ui/Components/UiIcon/UiIcon';
import { faFloppyDisk } from '@fortawesome/pro-solid-svg-icons';
import { Wrapper } from 'helpers/wrapper';

const ChipBar: React.FC<type.ChipBarProps> = (props): JSX.Element | null => {
    const { t: translate } = useTranslation();
    const userInfo = useRecoilValue(UserInfo);
    const { setUserSettings } = useUserLocalStorageSettings([`table.${props.table.tableKey}Table`]);
    const { getUserSettingTable } = useUserLocalStorageSettingsTable();

    const resetValue = (filter) => {
        props.table.tableKey &&
            setUserSettings(`table.${props.table.tableKey}Table`, {
                pageSize: props.table.state.pageSize,
                orderBy: props.table.state.sortBy,
                hiddenColumns: props.table.state.hiddenColumns,
                filter: getUserSettingTable(`table.${props.table.tableKey}Table`)?.filter.filter(
                    (currentFilter) => currentFilter.id !== filter.id
                )
            });

        props.table.setFilter(filter.id, undefined);
        props.setCurrentFilters((currentFilters) => {
            return currentFilters.filter((currentFilter) => currentFilter.id !== filter.id);
        });
    };

    return props.currentFilters.length > 0 ? (
        <>
            <TitleFilter variant='caption'>{translate('t.active_filters')}: </TitleFilter>

            <ContainerFilter>
                {props.currentFilters.map((filter, key) => {
                    const selectValue: string =
                        (filter.value.humanValue &&
                            !DateTime.fromISO(filter.value.humanValue).invalid &&
                            DateTime.fromISO(filter.value.humanValue).toFormat(
                                userInfo.user?.userSetting.dateFormat || 'YYYY-MM-DD'
                            )) ||
                        filter.value.humanValue ||
                        filter.value.value;
                    const currentValue: string[] = selectValue?.split(';');
                    const index: number = currentValue?.length === 2 ? 1 : 0;
                    return (
                        currentValue?.length && (
                            <Chip
                                data-testid={`Chip-Filter-${props.table.tableKey}Table${filter.id}`}
                                sx={{ margin: '4px 0px 2px 4px' }}
                                variant={VARIANT_CHIP}
                                key={key}
                                label={
                                    <ChipBarContent>
                                        {getUserSettingTable(`table.${props.table.tableKey}Table`)?.filter.find(
                                            (currentFilter) => currentFilter.id === filter.id
                                        ) && <UiIcon icon={faFloppyDisk} />}
                                        {filter.value.name}: <strong>{currentValue[index]}</strong>
                                    </ChipBarContent>
                                }
                                onDelete={() => {
                                    resetValue(filter);
                                }}
                            />
                        )
                    );
                })}
            </ContainerFilter>
        </>
    ) : null;
};

export default Wrapper(ChipBar);

import React, { useEffect, useState } from 'react';
import { CustomTimePicker, UiTimePickerContainer } from './UiTimePicker.style';
import { UiTimePickerProps } from './UiTimePicker.type';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';
import { Is12HrsFormat } from 'helpers/Converters';
import useConverter from 'components/CustomHooks/Converter/Converter';
import { DateTime } from 'luxon';
import { Dialog, DialogActions, DialogContent, TextField } from '@mui/material';
import { firstCapital, snakeToCamel } from 'helpers/converter/text';
import HelperText from 'components/Mixs/HelperText/HelperText';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';
import DateTimeDialogSelector from './DateTimeDialogSelector/DateTimeDialogSelector';
import UiButton from '../UiButton/UiButton';

const UiTimePicker: React.FC<UiTimePickerProps> = (props): JSX.Element => {
    const { t: translate } = useTranslation();
    const [openDate, setOpenDate] = useState<boolean>(false);
    const is12HrsFormat = useRecoilValue(Is12HrsFormat);
    const { dateTimeFormat } = useConverter();
    const [dateValue, setDateValue] = useState<DateTime | null>(DateTime.local());
    const [dateValueStaticDatePicker, setDateValueStaticDatePicker] = useState<DateTime | null>(dateValue);
    const [dateTimeError] = useState<boolean>(false);

    useEffect(() => {
        props.value && setDateValue(DateTime.isDateTime(props.value) ? props.value : DateTime.fromISO(props.value));
    }, [props.value]);

    useEffect(() => {
        dateValue && setDateValueStaticDatePicker(dateValue);
    }, [dateValue]);

    useEffect(() => {
        return () => {
            setDateValue(null);
            setDateValueStaticDatePicker(null);
        };
    }, []);

    return (
        <UiTimePickerContainer data-testid='UiTimePickerContent'>
            <LocalizationProvider dateAdapter={AdapterLuxon}>
                <CustomTimePicker
                    label={props.label || translate('t.time')}
                    value={dateValue}
                    ampm={is12HrsFormat}
                    onChange={(newDate: DateTime): void => {
                        if (newDate === null || newDate === 'null') {
                            setDateValue(null);
                            props.onDateChange && props.onDateChange(null);
                        } else {
                            setDateValue(newDate);
                            props.onDateChange && props.onDateChange(newDate);
                        }
                    }}
                    format={dateTimeFormat('time', Boolean(props.displaySeconds))}
                    open={false}
                    onOpen={() => {
                        setOpenDate(true);
                        dateValueStaticDatePicker.invalid && setDateValueStaticDatePicker(DateTime.local());
                    }}
                    slots={{ textField: TextField }}
                    slotProps={{
                        textField: (params) => {
                            return {
                                margin: 'normal',
                                id: `UiDatePicker${Math.ceil(Math.random() * 1000000000000000000)}`,
                                'data-testid': `UiDatePicker${
                                    props.testId ||
                                    firstCapital(snakeToCamel((props.label || translate('t.time')).replaceAll(' ', '_')))
                                }`,
                                size: 'small',
                                variant: 'outlined',
                                sx: { margin: '0 0 25px 0 !important' },
                                helperText: (
                                    <HelperText
                                        data-testid={`error$${
                                            props.testId ||
                                            firstCapital(
                                                snakeToCamel((props.label || translate('t.time')).replaceAll(' ', '_'))
                                            )
                                        }`}
                                        error={dateTimeError}
                                        text={translate('t.the_date_format_is_invalid')}
                                    />
                                ),
                                error:
                                    params.value &&
                                    typeof params.value.invalid !== 'undefined' &&
                                    params.value.invalid !== null
                                        ? false
                                        : props.error || params.error,

                                InputLabelProps: {
                                    shrink: true
                                },
                                inputProps: {
                                    ...params.inputProps,
                                    placeholder: dateTimeFormat('time', Boolean(props.displaySeconds)),
                                    style: { width: '100%' }
                                },
                                onFocus: () => props.onFocus && props.onFocus(),
                                onBlur: () => props.onBlur && props.onBlur()
                            };
                        }
                    }}
                />

                <Dialog open={openDate} onClose={() => setOpenDate(false)}>
                    <DialogContent>
                        <DateTimeDialogSelector
                            dateValueStaticDatePicker={dateValueStaticDatePicker}
                            setDateValueStaticDatePicker={setDateValueStaticDatePicker}
                            displaySeconds={Boolean(props.displaySeconds)}
                        />
                    </DialogContent>
                    <DialogActions>
                        <UiButton
                            color='primary'
                            testid={`${props.testId || ''}-UiTimePicker-cancel-button`}
                            variant='outlined'
                            onClick={() => setOpenDate(false)}
                            size='small'
                        >
                            {translate('t.cancel')}
                        </UiButton>
                        <UiButton
                            color='primary'
                            testid={`${props.testId || ''}-UiTimePicker-ok-button`}
                            variant='contained'
                            size='small'
                            onClick={() => {
                                if (!dateValueStaticDatePicker.invalid) {
                                    setDateValue(dateValueStaticDatePicker);
                                    props.onDateChange && props.onDateChange(dateValueStaticDatePicker);
                                }
                                setOpenDate(false);
                            }}
                        >
                            OK
                        </UiButton>
                    </DialogActions>
                </Dialog>
            </LocalizationProvider>
        </UiTimePickerContainer>
    );
};

export default UiTimePicker;

import React from 'react';
import * as Yup from 'yup';
import { FormikProps, SendDataProps } from './AnnouncementsActions.type';
import { useTranslation } from 'react-i18next';
import { FormDiv } from './AnnouncementsActions.style';
import { Form, Formik } from 'formik';
import { Grid, TextField } from '@mui/material';
import Checkbox from '../Ui/Components/UiCheckbox';
import HelperText from '../Mixs/HelperText';
import UiDateRangePicker2 from 'components/Ui/Components/UiDateRangePicker2/UiDateRangePicker2';
import { DateTime } from 'luxon';
import RegionCustomerSelector from 'components/Customer/RegionCustomerSelector/RegionCustomerSelector';

export const AnnouncementsForm: React.FC<FormikProps> = (props): JSX.Element => {
    const { t: translate } = useTranslation();

    const FormSchema = Yup.object().shape({
        message: Yup.string().required().required(translate('t.required')),
        dateFrom: Yup.number().required(),
        dateTo: Yup.number().required(),
        active: Yup.boolean(),
        allCustomers: Yup.boolean(),
        customer: Yup.array(),
        translations: Yup.array()
    });

    return (
        <FormDiv data-testid='AnnouncementsForm-container'>
            <Formik
                initialValues={props.formValues}
                validationSchema={FormSchema}
                enableReinitialize={true}
                onSubmit={(values) => {
                    props.sendData(JSON.parse(JSON.stringify(values)) as SendDataProps);
                }}
            >
                {(formikProps) => (
                    <Form id='announcements-form'>
                        <Grid container spacing={2}>
                            <Grid item xs={12} md={12} lg={12}>
                                <TextField
                                    id='message-input'
                                    name='message'
                                    label={translate('t.message')}
                                    variant='outlined'
                                    error={!!formikProps.errors.message && !!formikProps.touched.message}
                                    value={formikProps.values.message}
                                    fullWidth
                                    helperText={
                                        <HelperText
                                            error={!!formikProps.errors.message && !!formikProps.touched.message}
                                            text={formikProps.errors.message}
                                            defaultText={translate('t.required')}
                                        />
                                    }
                                    onChange={formikProps.handleChange}
                                    onBlur={formikProps.handleBlur}
                                    inputProps={{
                                        form: {
                                            autocomplete: 'off'
                                        },
                                        'data-testid': 'message-input'
                                    }}
                                    InputLabelProps={{
                                        shrink: true
                                    }}
                                    size='small'
                                />
                            </Grid>
                            <Grid item xs={12} md={12} lg={12}>
                                <UiDateRangePicker2
                                    validFutureDate
                                    dateFrom={DateTime.fromMillis(formikProps.values.dateFrom)}
                                    dateTo={DateTime.fromMillis(formikProps.values.dateTo)}
                                    dateFromLabel={translate('t.date_from')}
                                    dateToLabel={translate('t.date_to')}
                                    onDateChange={(newDate) => {
                                        if (newDate && !newDate[0].invalid && newDate[0] != '') {
                                            formikProps.setFieldValue('dateFrom', newDate[0].valueOf(), true);
                                        } else {
                                            formikProps.setFieldValue('dateFrom', NaN, true);
                                        }

                                        if (newDate && !newDate[1].invalid && newDate[1] != '') {
                                            formikProps.setFieldValue('dateTo', newDate[1].valueOf(), true);
                                        } else {
                                            formikProps.setFieldValue('dateTo', NaN, true);
                                        }
                                    }}
                                />
                            </Grid>
                            <Grid item xs={6} md={6} lg={6}>
                                <Checkbox
                                    testid='all-customers-checkbox'
                                    label={translate('t.all_customers')}
                                    name='allCustomers'
                                    checked={formikProps.values.allCustomers || false}
                                    onChange={formikProps.handleChange}
                                />
                            </Grid>
                            <Grid item xs={6} md={6} lg={6}>
                                <Checkbox
                                    testid='active-checkbox'
                                    label={translate('t.active')}
                                    name='active'
                                    checked={formikProps.values.active || false}
                                    onChange={formikProps.handleChange}
                                />
                            </Grid>
                            <Grid item xs={12} md={12} lg={12}>
                                <RegionCustomerSelector
                                    selectedCustomers={formikProps.values.customer}
                                    disabled={formikProps.values.allCustomers}
                                    onChange={(_event, newValue) => {
                                        formikProps.setFieldValue('customer', newValue, false);
                                    }}
                                />
                            </Grid>
                        </Grid>
                    </Form>
                )}
            </Formik>
        </FormDiv>
    );
};

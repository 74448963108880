import React, { useEffect, useMemo, useReducer } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { useMutation } from '@tanstack/react-query';
import Report from '../../../api/Report';
import { useTranslation } from 'react-i18next';
import { ExportedReportsContent } from './ExportedReports.view';
import { ExportedReportActions, ExportedReportsProps, ReportStatus } from './ExportedReports.type';
import { ButtonAction, CustomCell, IconStatus, ReportName } from './ExportedReports.style';
import CircularProgress from '@mui/material/CircularProgress';
import { ReportCancel, ReportCancelResponse, ReportModel } from '../../../models/Report.type';
import UiIcon from 'components/Ui/Components/UiIcon/UiIcon';
import { RefreshTable } from '../../Ui/Components/UiTable2/UiTable.atom';
import { ExportedReportInitalState, ExportedReportReducer } from './ExportedReport.reducer';
import { Success } from '../../Popup/Popup';
import useConverter from 'components/CustomHooks/Converter/Converter';
import { DateTime } from 'luxon';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';
import { UserInfo } from 'states/global/User';
import { BACKGROUND_BLUE } from 'components/Ui/colors';
import { Theme } from 'states/global/Theme';
import { Column } from 'models/Table.type';
import UiDatePickerFilter from 'components/Ui/Components/UiDatePickerFilter/UiDatePickerFilter';

const reportApi = new Report();

const ExportedReports: React.FC<ExportedReportsProps> = (): JSX.Element => {
    const [state, dispatch] = useReducer(ExportedReportReducer, ExportedReportInitalState);
    const { t: translate } = useTranslation();
    const userInfo = useRecoilValue(UserInfo);
    const { fromUTCToTimezone } = useConverter();
    const setRefreshTableAtom = useSetRecoilState(RefreshTable('exportedData-RefreshTableAtom'));
    const ThemeMode = useRecoilValue(Theme);

    const reportStatus: ReportStatus = useMemo(() => {
        return {
            done: {
                label: translate('t.done'),
                icon: ['far', 'circle-check'],
                color: '#2f6c2f',
                cellColor: 'rgb(234 255 233)'
            },
            running: {
                label: translate('t.processing'),
                icon: ['fas', 'person-running'],
                color: '#8c8c8c',
                cellColor: '#f9f9f9'
            },
            canceled: {
                label: translate('t.canceled'),
                icon: ['fas', 'file-slash'],
                color: '#9b9263',
                cellColor: '#fdf9e5'
            },
            error: {
                label: translate('t.error'),
                icon: ['fas', 'xmark'],
                color: '#a90329',
                cellColor: '#ffe1e1'
            },
            new: {
                label: translate('t.new'),
                icon: ['fas', 'file-circle-plus'],
                color: '#03b2cb',
                cellColor: '#ddfbff'
            },
            paused: {
                label: translate('t.paused'),
                icon: ['fas', 'pause'],
                color: '#383737',
                cellColor: '#e7e7e7'
            }
        };
    }, [translate]);

    const { mutate: cancelReportMutate, isLoading: cancelReportLoading } = useMutation<
        ReportCancelResponse,
        boolean,
        ReportCancel
    >(reportApi.cancel, {
        onSuccess: (data) => {
            if (data.status === 200) {
                Success({
                    text: translate('t.report_canceled')
                });
                setRefreshTableAtom(true);
                dispatch({
                    type: ExportedReportActions.SET_REPORT_ID_TO_CANCEL,
                    payload: undefined
                });
            }
        }
    });

    useEffect(() => {
        // Temporal fix until global is not provided for deleting invalid sorts
        const userId = userInfo?.user?.id;
        const userTableLS = userId ? localStorage.getItem(userId.toString()) : null;
        const parsedData = userTableLS ? JSON.parse(userTableLS) : {};

        if (parsedData['table'] && parsedData['table']['exportedDataTableTable']) {
            parsedData['table']['exportedDataTableTable']['orderBy'] = [];
        }

        if (userId && localStorage.getItem(userId.toString())) {
            localStorage.setItem(userId.toString(), JSON.stringify(parsedData));
        }
    }, []);

    const columns: Column<ReportModel>[] = [
        {
            Header: translate('t.report'),
            accessor: 'reportName',
            headerAlign: 'center',
            width: 280,
            disableFilters: true,
            classes: { backgroundColor: 'aqua' },
            Cell: (props: { row: { original: ReportModel } }) => {
                const reportSelected = state.report?.reportId === props.row.original.reportId;
                return (
                    <CustomCell
                        $selected={reportSelected}
                        background={reportStatus[props.row.original.status]?.cellColor}
                    >
                        <ReportName title={props.row.original.reportName}>{props.row.original.reportName}</ReportName>
                    </CustomCell>
                );
            },
            BackgroundTr: (original: ReportModel) => {
                const reportSelected = state.report?.reportId === original.reportId;

                const rowColor = {
                    light: reportSelected ? '#f60' : reportStatus[original.status]?.cellColor,
                    dark: BACKGROUND_BLUE
                };

                return rowColor[ThemeMode?.mode];
            }
        },
        {
            Header: translate('t.status'),
            accessor: 'requested_at',
            width: 180,
            Cell: (props: { row: { original: ReportModel } }) => {
                const reportSelected = state.report?.reportId === props.row.original.reportId;
                return (
                    <CustomCell
                        $selected={reportSelected}
                        background={reportStatus[props.row.original.status]?.cellColor}
                    >
                        {fromUTCToTimezone(DateTime.fromSeconds(props.row.original.requestedAt).toISO(), false)}
                        <IconStatus
                            title={reportStatus[props.row.original.status]?.label}
                            color={reportStatus[props.row.original.status]?.color}
                        >
                            <UiIcon size='sm' icon={reportStatus[props.row.original.status]?.icon} />
                        </IconStatus>
                    </CustomCell>
                );
            },
            Filter: ({ setFilter, state }) => {
                return (
                    <LocalizationProvider dateAdapter={AdapterLuxon}>
                        <UiDatePickerFilter
                            state={state}
                            setFilter={setFilter}
                            testId='RequestedFrom'
                            keyFilter='requestedFrom'
                            label={`${translate('t.created_at_from')}`}
                        />
                        <UiDatePickerFilter
                            state={state}
                            setFilter={setFilter}
                            testId='RequestedTo'
                            keyFilter='requestedTo'
                            label={`${translate('t.created_at_to')}`}
                        />
                    </LocalizationProvider>
                );
            }
        },
        {
            Header: translate('t.action'),
            accessor: 'reportId',
            disableFilters: true,
            disableSortBy: true,
            width: 190,
            Cell: (props: { row: { original: ReportModel } }) => {
                const reportSelected = state.report?.reportId === props.row.original.reportId;
                const loadingButton = cancelReportLoading && state.reportIdToCancel === props.row.original.reportId;
                return (
                    <CustomCell
                        $selected={reportSelected}
                        background={reportStatus[props.row.original.status]?.cellColor}
                    >
                        <ButtonAction
                            data-testid={reportSelected ? 'selected-report' : 'details-button'}
                            size='small'
                            startIcon={
                                <UiIcon size='5x' icon={reportSelected ? ['far', 'circle-check'] : ['far', 'list']} />
                            }
                            onClick={() => {
                                dispatch({
                                    type: ExportedReportActions.SET_REPORT_DETAIL,
                                    payload: props.row.original
                                });
                            }}
                        >
                            {translate(reportSelected ? 't.selected' : 't.details')}
                        </ButtonAction>
                        {props.row.original.status === 'done' ? (
                            <ButtonAction
                                disabled={!props.row.original.downloadLink}
                                data-testid='csv'
                                size='small'
                                startIcon={<UiIcon icon={['fas', 'download']} />}
                                $marginLeft={true}
                                href={`${props.row.original.downloadLink}`}
                            >
                                CSV
                            </ButtonAction>
                        ) : (
                            <ButtonAction
                                data-testid={loadingButton ? 'loading-button' : 'cancel-button'}
                                size='small'
                                startIcon={loadingButton ? undefined : <UiIcon icon={['fas', 'xmark']} />}
                                $marginLeft={true}
                                disabled={loadingButton || props.row.original.status === 'canceled'}
                                onClick={() => {
                                    dispatch({
                                        type: ExportedReportActions.SET_REPORT_ID_TO_CANCEL,
                                        payload: props.row.original.reportId
                                    });
                                    cancelReportMutate({
                                        reportId: props.row.original.reportId,
                                        reportType: props.row.original.reportType
                                    });
                                }}
                            >
                                {loadingButton ? <CircularProgress size='1.75em' /> : translate('t.cancel')}
                            </ButtonAction>
                        )}
                    </CustomCell>
                );
            }
        },
        {
            Header: '',
            disableFilters: true,
            width: 0,
            disableSortBy: true,
            accessor: 'requestedFrom'
        },
        {
            Header: '',
            disableFilters: true,
            width: 0,
            disableSortBy: true,
            accessor: 'requestedTo'
        }
    ];

    return (
        <ExportedReportsContent
            data-testid='ExportedReports-testid'
            columns={columns}
            report={state.report}
            reportStatus={reportStatus}
        />
    );
};

export default ExportedReports;

import React from 'react';
import * as style from './HubConnectionIndicator.style';
import * as type from './HubConnectionIndicator.type';
import UiIcon from 'components/Ui/Components/UiIcon/UiIcon';

export const HubConnectionIndicatorContent: React.FC<type.HubConnectionIndicatorViewProps> = ({
    isNotTransmittingHub,
    color,
    connectionType
}): JSX.Element => (
    <style.HubConnectionIndicatorContent data-testid='HubConnectionIndicatorContent'>
        <style.IndicatorIcon
            color={color}
            icon={['fas', connectionType === 'eth0' ? 'signal' : isNotTransmittingHub ? 'wifi-slash' : 'wifi']}
            size='sm'
        />
    </style.HubConnectionIndicatorContent>
);

import React from 'react';
import { TyreDetailsContent } from './TyreDetails.view';
import { VehicleDetailsWidgetAtom } from 'components/Vehicle/VehicleDetailsWidget/VehicleDetailsWidget.atom';
import { useRecoilValue } from 'recoil';
import { Value } from './TyreDetails.style';
import UiIcon from 'components/Ui/Components/UiIcon/UiIcon';
import { SensorAlert } from 'models/Wheel.type';
import { snakeToCamel } from 'helpers/converter/text';
import { MaxAlerts } from './TyreDetails.type';
import { VehicleModel } from 'models/Vehicle.type';
import { DateTime } from 'luxon';
import useConverter from 'components/CustomHooks/Converter/Converter';
import { Theme } from 'states/global/Theme';
import { applyStyleByMode } from 'helpers';
import { WHITE } from 'components/Ui/colors';

const TyreDetails: React.FC = (): JSX.Element => {
    const vehicleDetailsWidgetAtom = useRecoilValue(VehicleDetailsWidgetAtom());
    const { fromUTCtoUserTimezone } = useConverter();
    const ThemeMode = useRecoilValue(Theme);

    const getMaxAlerts = (sensorAlerts?: SensorAlert[]): MaxAlerts => {
        let alerts = {};

        if (sensorAlerts?.length) {
            for (let i = 0; i < sensorAlerts.length; i++) {
                let notification = sensorAlerts[i].notification;
                if (notification) {
                    if (!alerts[snakeToCamel(notification.type)]) {
                        alerts[snakeToCamel(notification.type)] = 0;
                    }
                    if (notification?.level > alerts[snakeToCamel(notification?.type)]) {
                        alerts[snakeToCamel(notification.type)] = notification.level;
                    }
                }
            }
        }
        return alerts;
    };

    const showMeasuredAt = (measuredAt: string | null, hasSensor: boolean): JSX.Element => {
        const isOutOfDate = (): boolean => {
            const today = DateTime.local();
            const diff = today.diff(DateTime.fromISO(measuredAt), 'minutes');
            return diff.minutes >= 30;
        };

        const outOfDateIcon: JSX.Element = isOutOfDate() ? (
            <UiIcon
                icon={['fas', 'hourglass-half']}
                color={applyStyleByMode({
                    styleOld: '#fff',
                    theme: ThemeMode?.mode,
                    light: '#0000008a',
                    dark: WHITE
                })}
            />
        ) : (
            <></>
        );

        if (!hasSensor) return <></>;
        return (
            <span>
                {' '}
                <Value alertLevel={0}>
                    {fromUTCtoUserTimezone({ date: measuredAt as string, format: 'dateTime', displaySeconds: false })}
                </Value>{' '}
                {outOfDateIcon}
            </span>
        );
    };

    return (
        <>
            {vehicleDetailsWidgetAtom.tyreDetails &&
                vehicleDetailsWidgetAtom.tyreDetails.map((tyre, index) => (
                    <TyreDetailsContent
                        key={index}
                        showMeasuredAt={showMeasuredAt}
                        vehicleDetails={vehicleDetailsWidgetAtom.vehicleDetails as VehicleModel}
                        tyreDetails={tyre}
                        getMaxAlerts={getMaxAlerts}
                    />
                ))}
        </>
    );
};

export default TyreDetails;

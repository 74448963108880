import React from 'react';
import { useRecoilValue } from 'recoil';
import { applyStyleByMode } from 'helpers';
import { Theme } from '../../../states/global/Theme';
import { SECONDARY_DARK_MODE, WHITE } from '../../Ui/colors';
import * as style from './HubBatteryIndicator.style';
import * as type from './HubBatteryIndicator.type';
import UiIcon from 'components/Ui/Components/UiIcon/UiIcon';

export const HubBatteryIndicatorContent: React.FC<type.HubBatteryIndicatorViewProps> = (props): JSX.Element => {
    const ThemeMode = useRecoilValue(Theme);
    return (
        <style.HubBatteryIndicatorContent data-testid='HubBatteryIndicatorContent'>
            {props.battery !== -1 ? (
                <UiIcon
                    color={applyStyleByMode({
                        styleOld: '#000',
                        theme: ThemeMode?.mode,
                        light: SECONDARY_DARK_MODE,
                        dark: WHITE
                    })}
                    icon={['fas', props.getIcon()]}
                    size='sm'
                />
            ) : (
                <></>
            )}
        </style.HubBatteryIndicatorContent>
    );
};

import React from 'react';
import { SensorsTableContainer } from './SensorsTable.style';
import { SensorsTableViewProps } from './SensorsTable.type';
import UiTable from 'components/Ui/Components/UiTable2/UiTable';
import Sensors from 'api/Sensors';

import { useTranslation } from 'react-i18next';
import Button from 'components/Ui/Components/UiButton/UiButton';
import { SIZE_BUTTON } from 'components/Ui/variables';
import { Download } from '@mui/icons-material';

const sensorsApi = new Sensors();
export const SensorsTableContent: React.FC<SensorsTableViewProps> = (props): JSX.Element => {
    const { t: translate } = useTranslation();

    const hidden: string[] = [
        'createdAtFrom',
        'createdAtTo',
        'measuredAtFrom',
        'measuredAtTo',
        'inactive',
        'isWheel',
        'sensorInfoStatus',
        'sensorBatteryVoltageFrom',
        'sensorBatteryVoltageTo'
    ];

    return (
        <SensorsTableContainer data-testid='SensorsTableContent'>
            <UiTable
                columns={props.columns}
                fetchFn={sensorsApi.getSensors}
                exportFn={sensorsApi.getSensorsExport}
                exportName='Sensors'
                refresh={false}
                hiddenColumns={hidden}
                queryKey='firstSensorPage'
                defaultSortBy={{
                    id: 'sensorSerialNumber',
                    desc: false
                }}
                defaultFilter={{
                    id: 'isWheel',
                    value: {
                        name: translate('t.sensors_attached_wheels'),
                        value: '1',
                        humanValue: 'True'
                    }
                }}
                actionsButton={
                    <Button
                        testid='Wheel-History-Button'
                        variant='contained'
                        size={SIZE_BUTTON}
                        startIcon={<Download />}
                        loading={props.isLoadingExportSensorWheelHistory}
                        disabled={!props.sensorIds.length}
                        onClick={() => {
                            props.exportSensorWheelHistoryRefetch();
                        }}
                    >
                        {translate('t.wheel_history')}
                    </Button>
                }
            />
        </SensorsTableContainer>
    );
};

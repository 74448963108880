import React from 'react';
import { PreviewUsers, UsersListContainer } from './AddAlertNotificationManagment.style';
import { AddAlertNotificationManagmentViewProps, InitialValuesType } from './AddAlertNotificationManagment.type';

import { useTranslation } from 'react-i18next';
import { object as YupObject, string as YupString } from 'yup';
import { Form, Formik, FormikProps } from 'formik';
import { AddAlertNotificationModel } from 'models/AlertNotification.type';
import { Grid } from '@mui/material';
import UiDatePicker from 'components/Ui/Components/UiDatePicker/UiDatePicker';
import { DateTime } from 'luxon';
import UiTimePicker from 'components/Ui/Components/UiTimePicker/UiTimePicker';
import LazyloadingAutocomplete from 'components/Mixs/LazyloadingAutocomplete/LazyloadingAutocomplete';
import { CancelButton, ContainerActions, CustomDialogActions } from 'components/StatedModal/Modal/Modal.style';
import User from 'api/User';
import UiButton from 'components/Ui/Components/UiButton/UiButton';
import { VARIANT_BUTTON } from 'components/Ui/variables';
import UserCard from './UserCard/UserCard';
import UiScroll from 'components/Ui/Components/UiScroll/UiScroll';
import { ModalActionTypesEnum } from 'states/global/Modal';
import UiIcon from 'components/Ui/Components/UiIcon/UiIcon';
import { faUsers } from '@fortawesome/pro-duotone-svg-icons';
import MuiDialogContent from '@mui/material/DialogContent';
import { diffDate, toCamelCase_H } from 'helpers';

const userApi = new User();

export const AddAlertNotificationManagmentContent: React.FC<AddAlertNotificationManagmentViewProps> = ({
    initialValues,
    userSelected,
    alertNotificationCallsUser,
    alertNotificationUserInvalid,
    loading,
    handleSubmit,
    modalDispatch,
    setUserSelected,
    addAlertNotificationCallsUser,
    removeAlertNotificationCallsUser,
    setAlertNotificationUserInvalid
}): JSX.Element => {
    const { t: translate } = useTranslation();
    const schemaValidateForm = YupObject().shape({
        date: YupString()
            .trim()
            .required(translate('p.this_field_is_required'))
            .test('is-luxon-date', '', (value) => DateTime.fromISO(value).isValid),
        timeFrom: YupString()
            .trim()
            .required(translate('p.this_field_is_required'))
            .test('is-luxon-time-from', '', (value) => DateTime.fromISO(value, { zone: 'utc' }).isValid),
        timeTo: YupString()
            .trim()
            .required(translate('p.this_field_is_required'))
            .test('is-luxon-time-to', '', (value) => DateTime.fromISO(value, { zone: 'utc' }).isValid)
    });

    return (
        <>
            <Formik
                initialValues={initialValues}
                onSubmit={(values: InitialValuesType) => {
                    handleSubmit(values);
                }}
                validationSchema={schemaValidateForm}
            >
                {(propsFormik: FormikProps<AddAlertNotificationModel>) => {
                    const { setFieldValue, setValues, values } = propsFormik;

                    const setActionData = (newData: DateTime | null) => {
                        setFieldValue('date', newData);
                    };

                    const setActionTimeFrom = (newData: DateTime | null) => {
                        if (DateTime.isDateTime(newData) && diffDate(newData, values.timeTo) >= 0) {
                            setValues({
                                ...values,
                                timeFrom: newData,
                                timeTo: newData.plus({ hours: 1 })
                            });
                        } else {
                            setFieldValue('timeFrom', newData);
                        }
                    };

                    const setActionTimeTo = (newData: DateTime | null) => {
                        if (DateTime.isDateTime(newData) && diffDate(values.timeFrom, newData) >= 0) {
                            setValues({
                                ...values,
                                timeFrom: newData.minus({ hours: 1 }),
                                timeTo: newData
                            });
                        } else {
                            setFieldValue('timeTo', newData);
                        }
                    };

                    return (
                        <Form data-testid='alert-notification-managment-form' id='alert-notification-managment-action-form'>
                            <MuiDialogContent sx={{ paddingTop: '20px' }}>
                                <Grid container justifyContent='center' direction='row' spacing={1}>
                                    <Grid item lg={3} md={6} sm={6} xs={12}>
                                        <UiDatePicker
                                            label={translate('t.date')}
                                            onlyDate
                                            value={values.date}
                                            onDateChange={setActionData}
                                            testId='AddAlertNotificationManagment-UiDatePicker-date'
                                        />
                                    </Grid>
                                    <Grid item lg={3} md={6} sm={6} xs={12}>
                                        <UiTimePicker
                                            label={toCamelCase_H(translate('t.time_from'))}
                                            value={values.timeFrom}
                                            onDateChange={setActionTimeFrom}
                                            testId='AddAlertNotificationManagment-UiTimePicker-timeFrom'
                                            displaySeconds
                                        />
                                    </Grid>
                                    <Grid item lg={3} md={6} sm={6} xs={12}>
                                        <UiTimePicker
                                            label={toCamelCase_H(translate('t.time_to'))}
                                            value={values.timeTo}
                                            onDateChange={setActionTimeTo}
                                            testId='AddAlertNotificationManagment-UiTimePicker-timeTo'
                                            displaySeconds
                                        />
                                    </Grid>
                                </Grid>

                                <Grid container justifyContent='center' spacing={1}>
                                    <Grid item>
                                        <LazyloadingAutocomplete
                                            data-testid='AddAlertNotificationManagment-LazyloadingAutocomplete-users'
                                            id={'data-export-top-bar'}
                                            size='small'
                                            label={translate('t.users')}
                                            query={userApi.getTable}
                                            version='v2'
                                            apiProject=''
                                            getOptionLabel={(option) => ({
                                                text: option.username,
                                                html: undefined
                                            })}
                                            queryId='usersDropdown'
                                            querySortBy={{ id: 'username', desc: false }}
                                            optionKey='username'
                                            optionValue='id'
                                            onValueChange={(userId, username) => {
                                                if (userId) {
                                                    setUserSelected({
                                                        ...userSelected,
                                                        user: userId || 0,
                                                        username: username,
                                                        priority: userSelected ? userSelected.priority + 1 : 1
                                                    });

                                                    setTimeout(() => {
                                                        setAlertNotificationUserInvalid(false);
                                                    }, 200);
                                                }
                                            }}
                                            defaultOption={{
                                                id: 0,
                                                name: '',
                                                icon: '',
                                                active: false
                                            }}
                                            error={alertNotificationUserInvalid}
                                            helperText={
                                                alertNotificationUserInvalid
                                                    ? translate('p.this_field_is_required')
                                                    : ''
                                            }
                                        />
                                    </Grid>
                                    <Grid item>
                                        <UiButton
                                            skin='success'
                                            form='tyre-action-form'
                                            color='primary'
                                            testid='AddAlertNotificationManagment-add-alertNotificationCallsUser-button'
                                            variant={VARIANT_BUTTON}
                                            type='submit'
                                            onClick={addAlertNotificationCallsUser}
                                        >
                                            {translate('t.add')}
                                        </UiButton>
                                    </Grid>
                                </Grid>

                                <UsersListContainer>
                                    <UiScroll height='305px'>
                                        <Grid container justifyContent='center' marginTop='4px' spacing={1}>
                                            {alertNotificationCallsUser.length ? (
                                                alertNotificationCallsUser.map((alertNotificationUser, key) => {
                                                    return (
                                                        <Grid item key={`Grid-${key}`}>
                                                            <UserCard
                                                                key={`UserCard-${key}`}
                                                                alertNotificationUser={alertNotificationUser}
                                                                removeAlertNotificationCallsUser={
                                                                    removeAlertNotificationCallsUser
                                                                }
                                                            />
                                                        </Grid>
                                                    );
                                                })
                                            ) : (
                                                <PreviewUsers>
                                                    <UiIcon icon={faUsers} size='7x' fixedWidth />
                                                </PreviewUsers>
                                            )}
                                        </Grid>
                                    </UiScroll>
                                </UsersListContainer>
                            </MuiDialogContent>

                            <ContainerActions>
                                <CustomDialogActions>
                                    <>
                                        <UiButton
                                            skin='success'
                                            form='tyre-action-form '
                                            color='primary'
                                            testid='AddAlertNotificationManagment-add-button'
                                            variant={VARIANT_BUTTON}
                                            type='submit'
                                            disabled={!(propsFormik.isValid && alertNotificationCallsUser.length)}
                                            loading={loading}
                                            onClick={() => {
                                                propsFormik.submitForm();
                                            }}
                                        >
                                            {translate('t.save')}
                                        </UiButton>
                                        <CancelButton
                                            data-testid='Modal-Cancel-Button'
                                            onClick={() => {
                                                modalDispatch({
                                                    type: ModalActionTypesEnum.CLOSE_MODAL
                                                });
                                            }}
                                            variant='outlined'
                                            color='primary'
                                            size='small'
                                        >
                                            {translate('t.cancel')}
                                        </CancelButton>
                                    </>
                                </CustomDialogActions>
                            </ContainerActions>
                        </Form>
                    );
                }}
            </Formik>
        </>
    );
};
